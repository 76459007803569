import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa, supabase } from '@supabase/auth-ui-shared'


const AdminPanel = () => {


  const supabase = createClient("https://yjqfvaqpwdiblozmnruy.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlqcWZ2YXFwd2RpYmxvem1ucnV5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ4MjQ0ODQsImV4cCI6MjAzMDQwMDQ4NH0.lKlUqGH7wJJCQatUBMUikAtRYH5LjuX41XSwXoYuqJ8");



  const [session, setSession] = useState();

  useEffect(() => {

    supabase.auth.getSession().then(({ data: { session } }) => { setSession(session) })
    const { data: { subscription }, } = supabase.auth.onAuthStateChange((_event, session) => { setSession(session) })
    return () => subscription.unsubscribe()



  }, [])

  if (!session) { console.log("ooo", session) }

  async function SignOut() {
    await supabase.auth.signOut();
    setSession(null);
    console.log('clicked')
  }




  if (!session) {
    return (

      <div className='w-full h-full flex justify-end items-center'>
        < div className='w-1/6 h-full m-20'>
          <Auth
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={[]}
          />
        </div>
      </div>

    )
  }
  else {
    return (<><ProfileView SignOut={SignOut}/></>)
  }
}






function ProfileView({SignOut}) {


  return (

    <>
      <button className='text-white' onClick={SignOut}>SignOut</button>
      <p>profile</p>
    </>

  );

}






// function ProfileView() {







//   const HndleUpload = async () => {

//     const { error } = await supabase
//       .from('countries')
//       .insert({ id: 1, name: 'Denmark' })

//     const [userInfo, setUserInfo] = useState({
//       name: '',
//       city: '',
//       state: '',
//       phone: '',
//       bloodGroup: '',
//       email: '',
//       address: '',
//       bikes: [{ make: '', model: '', year: '' }]
//     });

//     const handleChange = (e, index) => {
//       const { name, value } = e.target;
//       if (name === 'make' || name === 'model' || name === 'year') {
//         const newBikes = [...userInfo.bikes];
//         newBikes[index][name] = value;
//         setUserInfo({ ...userInfo, bikes: newBikes });
//       } else {
//         setUserInfo({ ...userInfo, [name]: value });
//       }
//     };

//     const addBike = () => {
//       setUserInfo({
//         ...userInfo,
//         bikes: [...userInfo.bikes, { make: '', model: '', year: '' }]
//       });
//     };

//     const handleSubmit = (e) => {
//       e.preventDefault();
//       console.log('Submitted', userInfo);
//     };

//     const handleDeleteBike = (index) => {
//       const newBikes = [...userInfo.bikes];
//       newBikes.splice(index, 1);
//       setUserInfo({ ...userInfo, bikes: newBikes });
//     };








//     const handleInsert = async (index) => {
//       const { data, error } = await supabase
//         .from('user')
//         .insert([
//           { some_column: 'someValue', other_column: 'otherValue' },
//         ])
//         .select()
//     };





//     return (
//       <div className='w-full h-screen bg-black pt-[100px] text-white'>



//         <h2>Admin Panel</h2>



//         <form onSubmit={handleSubmit}>
//           {/* ... (previous code) */}

//           <div>
//             <h3>Bike Information</h3>
//             {userInfo.bikes.map((bike, index) => (
//               <div key={index}>
//                 <input
//                   type="text"
//                   name="make"
//                   value={bike.make}
//                   onChange={(e) => handleChange(e, index)}
//                   placeholder="Make"
//                 />
//                 <input
//                   type="text"
//                   name="model"
//                   value={bike.model}
//                   onChange={(e) => handleChange(e, index)}
//                   placeholder="Model"
//                 />
//                 <input
//                   type="text"
//                   name="year"
//                   value={bike.year}
//                   onChange={(e) => handleChange(e, index)}
//                   placeholder="Year"
//                 />
//                 <button type="button" onClick={() => handleDeleteBike(index)}>
//                   Delete Bike
//                 </button>
//               </div>
//             ))}
//             <button type="button" onClick={addBike}>
//               Add Bike
//             </button>
//           </div>
//           <button type="submit">Submit</button>
//         </form>
//       </div>


//     );
//   }
// }


export default AdminPanel;
