import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient("https://yjqfvaqpwdiblozmnruy.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlqcWZ2YXFwd2RpYmxvem1ucnV5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ4MjQ0ODQsImV4cCI6MjAzMDQwMDQ4NH0.lKlUqGH7wJJCQatUBMUikAtRYH5LjuX41XSwXoYuqJ8");

function Datefetch() {
  
    const [record, setRecord] = useState([]);

  useEffect(() => {
    getCountries();
  }, []);

  async function getCountries() {
    const { data } = await supabase.from("countries").select();
    setRecord(data);
    console.log(record);
  }

  return record;
}

export default Datefetch;