import React, { useState, useContext } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";







const InsertBike = () => {




const [toggle, setToggle] = useState(false);
const { Refresh, token, refresh } = useContext(MyContext)


return(
<>
<button className='text-white hover:bg-orange-600 bg-orange-500 px-5 p-1 rounded-full' onClick={() => { setToggle(prevState => !prevState) }}>Add Bike</button>

{toggle &&
<BikeForm setToggle={setToggle}/>
}

</>

);    

}








const BikeForm = ({setToggle}) => {
        
    const {token, Refresh, refresh} = useContext(MyContext)
    const [uploadOk, setUploadOk] = useState(false);
    
    const [bike, setBike] = useState({
        user_id: token.user ? token.user.id : '', // Check if token.user exists
        username: '',
        maker: '',
        model: '',
        year: '',
        ownYear: '',
        number: ''
    });



    // Define models based on maker selection
    const modelsByMaker = {
        Yamaha: ['RX100', 'RX135', 'RX135 5-Speed', 'RXZ', 'RXZ 5-Speed'],
        Suzuki: ['Shogun', 'Samurai', 'Shaolin'],
        // Define models for other makers here
    };




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBike({ ...bike, [name]: value });
    };




    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { data, error } = await supabase
                .from('bike')
                .insert(bike)

            if (error) {
                console.error("Failed to insert bike data:", error.message);
                return;
            }

            console.log("bike Data inserted successfully:", data);
            // Clear the form after successful submission
            setBike({
                user_id: token.user ? token.user.id : '',
                username: '',
                maker: '',
                model: '',
                year: '',
                ownYear: '',
                number: ''
            });
            // Refresh();
            setUploadOk(true);
            console.log('data done')
            Refresh();
        } catch (error) {
            console.error("Error inserting data:", error.message);
        }
    };







    return (

        <div className="w-screen h-screen absolute top-0 left-0 backdrop-blur-sm bg-black/60 flex flex-col justify-center items-center">
        
        <p className="text-white text-3xl font-extrabold mb-5">Add Motorcycle</p>
        <div className="flex flex-col space-y-4 border border-gray-600 p-10 rounded-xl">
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <select
                name="maker"
                value={bike.maker}
                onChange={handleInputChange}
                className="border border-gray-300 rounded p-2"
            >
                <option value="">Select Maker</option>
                <option value="Yamaha">Yamaha</option>
                <option value="Suzuki">Suzuki</option>
                {/* Add other maker options here */}
            </select>

            {bike.maker && (
                <select
                    name="model"
                    value={bike.model}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded p-2"
                >
                    <option value="">Select Model</option>
                    {modelsByMaker[bike.maker].map((model, index) => (
                        <option key={index} value={model}>
                            {model}
                        </option>
                    ))}
                </select>
            )}

            <input
                type="text"
                name="year"
                value={bike.year}
                onChange={handleInputChange}
                placeholder="Year"
                className="border border-gray-300 rounded p-2"
            />

            <input
                type="text"
                name="ownYear"
                value={bike.ownYear}
                onChange={handleInputChange}
                placeholder="Own Year"
                className="border border-gray-300 rounded p-2"
            />

            <input
                type="text"
                name="number"
                value={bike.number}
                onChange={handleInputChange}
                placeholder="Bike Number"
                className="border border-gray-300 rounded p-2"
            />

            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                {uploadOk ?"Biked Added":"Add"}
            </button>
        </form>
        </div>
        <button className='text-white my-10 hover:text-orange-600' onClick={() => { setToggle(prevState => !prevState) }}>Close</button>

        </div>
    );
};





export default InsertBike;



