import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../client';



const Login = ({ setToken }) => {


  let navigate = useNavigate()


  const [formData, setFormData] = useState({
    email: '', password: ''
  })


  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value
      }

    })

  }

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
      })

      if (error) throw error
      setToken(data)
      navigate('/profile')


      //   alert('Check your email for verification link')


    } catch (error) {
      alert(error)
    }
  }




  return (
    <div className='fixed bg-black w-full h-full flex flex-col justify-center items-center text-white'>

      <div className='w-1/2 bg-slate-500 h-fit rounded-full overflow-hidden mb-5'>
        <form onSubmit={handleSubmit} className='space-x-0  flex'>

          <input

            className='p-3 text-gray-900 w-full'
            placeholder='Email'
            name='email'
            onChange={handleChange}
          />

          <input

            className='p-3 text-gray-900 w-full'

            placeholder='Password'
            name='password'
            type="password"
            onChange={handleChange}
          />

          <button type='submit' className='px-10 bg-orange-600 hover:bg-orange-500'>
            Submit
          </button>


        </form>
      </div>


      <div className='flex justify-center space-x-3 w-1/2 bg-slate-000 h-fit rounded-full overflow-hidden mb-5'>

        <p className=''>
          Don't have an account?
        </p>

        <Link className='text-orange-300' to='/signup'>Sign Up</Link>

      </div>
      {/* <img alt='bg' className='absolute top-0 left-0 w-full object-cover h-full' src={process.env.PUBLIC_URL + "./bg.jpg"} /> */}
    </div>
  )
}

export default Login