import { useEffect, useState } from 'react';

function useSafeAreaInsets() {
  const [insets, setInsets] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  });

  useEffect(() => {
    function updateInsets() {
      setInsets({
        top: parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--env(safe-area-inset-top)')) || 0,
        right: parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--env(safe-area-inset-right)')) || 0,
        bottom: parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--env(safe-area-inset-bottom)')) || 0,
        left: parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--env(safe-area-inset-left)')) || 0,
      });
    }

    window.addEventListener('resize', updateInsets);
    updateInsets(); // Initial call

    return () => {
      window.removeEventListener('resize', updateInsets);
    };
  }, []);

  return insets;
}

export default useSafeAreaInsets;
