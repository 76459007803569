import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../../client'
import AddNewProfile from './AddNewProfile'
import EditProfile from './EditProfile'
import InsertBike from '../posts/InsertBike'
import MyContext from '../Components/MyContext'
import InsertRidePost from '../Ride/InsertRidePost'
import MechanicProfileCreate from '../mechanic/mechanicProfileCreate'
import Delete from '../posts/Delete'
import MarketInsertPost from '../market/MarketInsertPost'





const UserProfile = ({ token }) => {


  let navigate = useNavigate()

  const [ride, setRide] = useState([]);
  const [record, setRecord] = useState([]);
  const [bikes, setBikes] = useState([]);
  const [adduser, setAdduser] = useState(false);
  const [friend, setFriend] = useState([]);

  const [edituser, setEdituser] = useState(false);

  const { username1, userData, setUsername1, Refresh, refresh, NavigateButton } = useContext(MyContext)



  useEffect(() => {
    async function fetchUserData() {
      const { data, error } = await supabase
        .from('user')
        .select('*')
        .filter('user_id', 'eq', token.user.id); // Filter by ID

      if (data && data.length > 0) {
        setRecord(data);
        setFriend(data[0].friends);
        // setUsername1(data[0].username);
        // localStorage.setItem('username1', data[0].username); // Store in localStorage
      } else {
        setAdduser(true);
      }
    }

    fetchUserData();
  }, [refresh]);





  //fetch bikes data
  useEffect(() => {

    async function fetchBike() {

      const { data, error } = await supabase
        .from('bike')
        .select('*')
        .filter('user_id', 'eq', token.user.id); // Filter by ID
      setBikes(data);
    }
    fetchBike();
  }, [refresh]);






  //fetch joined Rides 
  useEffect(() => {

    async function fetchRide() {

      const { data, error } = await supabase
        .from('ride')
        .select('*')
      // .filter('user_id', 'eq', token && token.user.id); // Filter by ID

      // .filter('join', 'eq', record.length > 0 && record[0].username); // Filter by ID
      setRide(data);
      console.log('rideData', data)
    }

    fetchRide();

  }, [refresh]);






  return (
    <div className='w-full grid grid-cols-4 h-full justify-start items-center py-0  bg-black fixed'>

      <div className='border border-slate-900 overflow-y-auto h-[100%] px-10 py-20 '>

        {record && record.length > 0 && <div className='flex flex-col justify-center items-center'>

          <div className='rounded-full flex w-[15vw] h-[15vw] overflow-hidden mb-5'>
            <img className='object-cover' src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${record[0].id}.jpg?c=1`} />
          </div>

          <div className='flex overflow-hidden flex-col justify-center items-center text-center space-y-3 mb-10'>
            <p className='text-[1.8vw] font-bold text-white text-wrap break-words'>{record[0].name}</p>
            <p className='text-[1vw] font-base text-white'>@{username1}</p>

            <p className='text-[1vw] font-base text-white'>Blood-Group {record[0].bloodGroup}</p>
            <p className='text-[1vw] font-semibold text-white'>{record[0].city}, {record[0].state}, {record[0].country}</p>
          </div>

          <p className='text-[2.2vw] font-bold text-white'>{record[0].bike}</p>


          <p className='text-[1vw] font-base text-white mt-5'>Club</p>
          <p className='text-[1.3vw] font-semibold  text-white'>{record[0].club}</p>
          <span className='font-thin text-gray-300 mr-1'>Member</span>


          {/* {bikes.map((bike, index) => ( <p key={index} className='text-[3vw] font-bold text-white'> Riding - {bike.maker} </p> ))} */}


          <div className='w-full h-full p-10 my-5 border-y border-gray-800 space-y-5 '>
            <p className='text-[1vw] font-thin text-white text-center'>About Myself</p>
            <p className='text-[1.2vw]  text-white text-center'>{record[0].bio} </p>
          </div>

          <button className='text-white mb-10' onClick={() => { setEdituser(prevState => !prevState) }}>Edit Profile</button>

        </div>}

      </div>

      {/* <h2>Personal</h2>
       {Object.entries(record).map(([key, value]) => (
         <div key={key}>
           <p>{key}: {value}</p>
         </div>
       ))}
 
       <p>{data.id}nmnmm</p> */}

      <div className='border border-slate-900 border-yoverflow-scroll h-[100%] px-0 py-20 col-span-2'>
        <p className='text-white text-2xl mb-2 '>My Rides</p>

        <div className='border-y border-slate-800  p-3 flex items-start space-x-3 w-full overflow-scroll'>

          {ride && ride.length > 0 && (
            ride.map((rides, index) => (
              // <p className='text-white' key={index}>{rides}</p>
              // <NavigateButton path={'/ride/' + rides.id} name={rides.header} />
              rides.joinBy.includes(username1) &&

              <RideView Data={rides} key={index} />
            ))
          )}



        </div>


      </div>

      <div className='border border-slate-900 bg-red-000 overflow-scroll h-full px-10 py-20 space-y-5'>


        <MechanicProfileCreate />
        <InsertRidePost token={token.user.id} />
        <MarketInsertPost />

        {/* my friends */}
        <div className='bg-gray-500/20 p-3 rounded-xl flex flex-col items-start space-y-3 h-max'>
          <p className='text-white text-2xl mb-2'>Friends</p>

          {userData && userData[0].friends && userData[0].friends.map((friend, index) => (

            <button key={index} className='text-white hover:bg-gray-800 rounded-xl duration-700 px-5 border border-gray-800 py-1' onClick={() => navigate(`/${friend}`)}>@{friend}</button>

          )
          )}

        </div>


        {/* my Garage */}
        <div className='bg-gray-500/20 p-3 rounded-xl flex flex-col items-start space-y-3'>

          <div className='grid grid-cols-2 gap-5 justify-center items-center w-full border-b border-gray-700 pb-3'>
            <p className='text-white text-2xl mb-2'>My Garage</p>
            <InsertBike token={token.user.id} />
          </div>

          {bikes.length > 0 && (bikes.map((bike, index) => (<BikeView key={index} bikeData={bike} />)))}

        </div>



      </div>



      {adduser && <AddNewProfile userId={token.user.id} />}

      {edituser && <EditProfile userId={token.user.id} record={record} setEdituser={setEdituser} />}


    </div>
  )
}







function BikeView({ bikeData }) {

  const { maker, model, ownYear, year, number, id } = bikeData;

  return (

    <div className='bg-gray-500/10 p-3 w-full h-auto flex justify-between'>

      <div>
        <p className='text-white text-lg font-semibold mb-2' >{maker} {model}</p>
        <p className='text-gray-400 text-sm' >Make Year {year}</p>
        <p className='text-gray-400 text-sm' >Own Year {ownYear}</p>
        <p className='text-gray-400 text-sm' >Number {number}</p>
      </div>
      <div className='flex justify-start items-start'>
        <Delete id={id} table='bike' item={model} />
      </div>
    </div>
  );
}


function RideView({ Data }) {

  const { header, date, joinBy, status, id } = Data;


  let navigate = useNavigate()

  return (

    <div className='w-[300px] space-y-2 shrink-0 h-[170px] text-white bg-gray-500/10 hover:bg-gray-800 rounded-xl duration-700 px-5 border border-gray-800 py-1 flex flex-col items-start justify-center' onClick={() => navigate('/ride/' + id)} >
      <p className='text-xl font-bold'> {header} </p>
      <p className='text-md'> {date} </p>
      <p className='text-md'> Join By {joinBy ? joinBy.length : 0} People </p>
      {status && <p className='text-md font-bold'>Status <span className='mx-3 uppercase'>{status}</span></p>}
    </div>
  );
}




export default UserProfile




