import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import MyContext from '../Components/MyContext';
import { supabase } from '../../client';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import UpdateArray from '../posts/UpdateArray';


const ViewRideSingle = () => {



    const [record, setRecord] = useState({});
    const { username1, Refresh, token, refresh } = useContext(MyContext);
    const navigate = useNavigate();
    const [added, setAdded] = useState(false);
    const [friendsList, setFriendsList] = useState([]);


    const { rideId } = useParams();




    useEffect(() => {
        async function fetchUserData() {
            const { data, error } = await supabase
                .from('ride')
                .select('*')
                .filter('id', 'eq', rideId); // Filter by ID

            if (data && data.length > 0) {
                setRecord(data[0]);
                setFriendsList(data[0].joinBy || []);
                console.log(data[0].haltPoints);
                // localStorage.setItem('username1', data[0].username); // Store in localStorage
            }

        }

        fetchUserData();
    }, []);












    // useEffect(() => {
    //     const fetchFriendsList = async () => {
    //         try {
    //             const { data, error } = await supabase
    //                 .from('ride')
    //                 .select('join')
    //                 .eq('id', rideId)
    //                 .single();

    //             if (error) {
    //                 console.error("Failed to fetch friends list:", error.message);
    //                 return;
    //             }

    //             setFriendsList(data.joinBy || []);
    //         } catch (error) {
    //             console.error("Error fetching friends list:", error.message);
    //         }
    //     };

    //     fetchFriendsList();
    // }, [rideId]);

    const handleSubmit = async () => {
        try {
            const updatedFriendsList = [...friendsList, username1];
            const { data, error } = await supabase
                .from('ride')
                .update({ joinBy: updatedFriendsList })
                .eq('id', rideId)
                .select();

            // UpdateArray("user", "ride", "user_id", token.user.id, rideId);
            Refresh();

            if (error) {
                console.error("Failed to join:", error.message);
                return;
            }

            console.log("Join successfully:", data);
        } catch (error) {
            console.error("Error joining ride:", error.message);
        }
    };

    const { id, created, header, status, haltPoints, from, to, end, description, perHead, time, date, joinBy, completed, cancel, user_id, host } = record;

    // const datawoho = JSON.parse(haltPoints);


    return (

        <div className="fixed w-full h-full flex justify-center items-center overflow-scroll bg-black">
            {/* { record.length > 0 &&  */}
            <div className="w-full h-full overflow-scroll  my-20 rounded-2xl  p-5 flex justify-center bg-black/50 backdrop-blur-md">

                <div className='w-[90%] h-max p-10 flex space-y-10 py-20 flex-col bg-black border rounded-3xl border-gray-600'>

                    <div className='h-[85%] border-0 bg-slate-000 col-span-5 bg-red-000  space-y-3 flex flex-col justify-center'>
                        <p className="text-white text-xl font-bold">{header} - <span className='border-b p-1'>{status}</span></p>
                        <p className="text-gray-400 text-sm flex items-center space-x-3">Date: {date} | Time: {time}</p>

                        <p className="text-gray-400 text-sm pt-5 ">Start from {from}</p>
                        <p className="text-gray-400 text-sm pt-5 ">for{to}</p>
                        <p className="text-gray-400 text-sm pt-5 ">end at <span className='border border-gray-300 rounded-full mx-1 py-1 px-5'>{end}</span>via</p>

                    </div>



                    <div className='h-[35px] text-center rounded-xl col-span-1 hover:bg-orange-600 bg-orange-500 space-y-1 flex flex-col justify-center'>

                        {friendsList.includes(username1)
                            ? <p className='text-white text-1xl font-semibold'> Ride Join by you. </p>
                            : <button className='text-white text-3xl font-semibold' onClick={handleSubmit}> Join Ride </button>
                        }

                    </div>



                    <div className='h-[85%] border-0 bg-slate-000 col-span-6 my-5 bg-red-000 flex flex-col justify-start items-start'>

                        <p className='text-white mb-3'>Ride Halt/Stop Points</p>
                        <div className='flex justify-start w-full'>
                            {haltPoints && haltPoints.map((point, index) => (<HaltView key={index} point={point} index={index} />))}
                        </div>
                    </div>


                    <div className='min-h-[100px] text-center px-5 rounded-xl col-span-5 bg-gray-500/10 space-y-2 flex flex-col justify-center'>
                        <p className="text-orange-400 text-lg">About Ride</p>
                        <p className="text-gray-400 text-md break-words">{description}</p>
                    </div>


                    <div className='min-h-[100px] text-center rounded-xl col-span-1 bg-gray-500/10 space-y-1 shrink-0 flex flex-col justify-center'>
                        <p className="text-orange-400 text-lg">Contribution</p>
                        <p className="text-gray-400 text-md">₹{perHead}</p>
                    </div>


                    <div className='h-full text-center rounded-xl col-span-6 py-2 flex-wrap bg-gray-500/10 space-x-3 flex flex-col justify-center items-center'>

                        <p className="text-white text-lg mb-5">Ride join by</p>

                        <div className='flex flex-wrap'>
                            {joinBy && joinBy.map((rider, index) => <p key={index} className="py-1 m-1 px-5 rounded-full bg-gray-300/10 text-gray-400 text-sm">{rider}</p>)}
                        </div>

                    </div>

                    <div className='h-[15%] text-center space-x-3 py-3'>

                        <p className="text-gray-500 text-xl">Ride Organised by <br/>
                            <span className='text-orange-400 hover:text-orange-200 px-2'>
                                <button onClick={() => navigate(`/${host}`)}>{host}</button>
                            </span> <br/>on {created && created.slice(0, 10)} 
                        </p>

                    </div>

                    <div className='h-[15%] col-span-1 flex items-left space-x-3 bg-slate-000 py-3'></div>
                </div>
            </div>

        </div>
    );
};



function HaltView({ point, index }) {

    const data = JSON.parse(point);

    return (
        <div key={index} className="bg-gray-400/10 m-1 w-fit h-auto p-5 rounded-xl">

            <p className='text-gray-500 text-sm'>{index + 1}-Halt Point:</p>
            <p className='text-white text-xl'>{data.haltPoint}</p>

            <p className='text-gray-500 text-sm mt-2'>Halt Duration:</p>
            <p className='text-white text-sm'> {data.time}</p>

            <p className='text-gray-500 text-sm mt-2'>Activity: </p>
            <p className='text-white text-sm'>{data.description}</p>

        </div>
    );


}



export default ViewRideSingle;
