import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MyContext from './Components/MyContext';
import { supabase } from '../client';












const Members = () => {




  const [record, setRecord] = useState([]);
  const [user, setUser] = useState();
  const [filterby, setFilterby] = useState({ all: true, city: false, state: false, club: false, friends: false, search: false });
  const { username1, Refresh, token, refresh, userData } = useContext(MyContext);
  const [search, setSearch] = useState('');


  useEffect(() => {
    getCountries();
  }, []);

  async function getCountries() {
    const { data } = await supabase.from("user").select();
    setRecord(data);
  }



  useEffect(() => {
    getUser();
  }, [token]);

  async function getUser() {
    const { data, error } = await supabase
      .from('user')
      .select('*')
      .filter('user_id', 'eq', token && token.user.id); // Filter by ID
    console.log('user', data);

    setUser(data);
  }


  function handleSearch(e) {
  
    setSearch(e.target.value);
    setFilterby({search:true});
  
  }

  return (
    <div className="fixed w-full h-screen bg-black overflow-scroll grid grid-flow-col grid-col-8">

      <div className='span-col-2 bg-slate-500/10 px-10 pt-10 space-y-5 flex flex-col justify-start items-start'>

        <p className='text-orange-400 text-5xl mb-5'>Explore</p>


        <input
          className="p-2 w-[100%] rounded-full border outline-none"
          type="text"
          placeholder="Search..."
          name='search'
          value={search}
          onChange={handleSearch}
        />

        <p className='text-white'>{search}</p>
        <button className='ml-1 text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ city: true })}>By my city </button>
        <button className='ml-1 text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ state: true })}>By my state</button>
        <button className='ml-1 text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ club: true })}>By my Club</button>
        {/* <button className='ml-1 text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ friends: true })}>By my Friends</button> */}
        <button className='ml-1 text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ all: true })}>clear filter</button>

      </div>


      {user && 
      <div className="span-col-4 grid grid-cols-2 gap-5 px-20 place-content-start py-20">

        {filterby.all && record.map((member, index) => <MemberCardMobile key={index} data={member} />)}
        {filterby.city && record.filter(member => member.city === user[0].city).map((member, index) => (<MemberCardMobile key={index} data={member} />))}
        {filterby.state && record.filter(member => member.state === user[0].state).map((member, index) => (<MemberCardMobile key={index} data={member} />))}
        {filterby.club && record.filter(member => member.club === user[0].club).map((member, index) => (<MemberCardMobile key={index} data={member} />))}
        {/* {filterby.friends && record.filter(member => member.friends.toLowerCase().includes(user[0].username.toLowerCase())).map((member, index) => (<MemberCardMobile key={index} data={member} />))} */}
        {filterby.search && record.filter(member => member.name.toLowerCase().includes(search.toLowerCase())).map((member, index) => ( <MemberCardMobile key={index} data={member} /> ))}

      </div>
    }

    </div>
  );
};














const MemberCardMobile = (props) => {

  const { name, club, state, city, id, bike,username } = props.data;
  
  const navigate = useNavigate();

  return (

    <div onClick={()=>navigate(`/${username}`)} className="cursor-pointer rounded-2xl flex items-center overflow-hidden h-[150px] space-x-3 p-2 bg-gray-500/10 hover:border-gray-800 duration-300" >

      <div className="bg-red-600 border h-[120px] w-[120px] flex rounded-full overflow-hidden" >
        <img className='object-cover w-full flex' src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${id}.jpg?c=1`} />
      </div>

      <div className='text-left space-y-1 bg-gray-000'>
        <p className="text-white font-bold text-lg m-auto" >{name}</p>
        <p className="font-semibold text-orange-400 text-sm m-auto" >@{username}</p>
        
        <p className="text-white font-thin text-xs lg:text-sm">{city}, {state}</p>
        <p className="text-white font-semibold text-sm">{bike}</p>
        <p className="text-gray-400 text-xs">{club}</p>
      </div>

    </div>
  );
};






export default Members;
