import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import { supabase } from '../client';



const MembersView = () => {

  const { userId } = useParams();

  const [record, setRecord] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {

    getUser();

    async function getProfileImage() {
      const { data, error } = await supabase.storage.from('avatars').download('public/avatar1.png')
      setProfileImage(data);
      setIsLoading(false);

    }

    getProfileImage();

  }, []);




  async function getUser() {


    try {
      // Use the 'select' method to query the table
      const { data, error } = await supabase
        .from('user')
        .select('*')
        .filter('username', 'eq', userId); // Filter by ID

      setRecord(data);

      if (error) {
        console.error('Error fetching data:', error.message);
        return null;
      }

      // Return the fetched data
      return data;
    } catch (error) {
      console.error('Error fetching data:', error.message);
      return null;
    }


  }



  const handleFileSelect = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    setSelectedFile(file); // Update state with the selected file
    console.log(file);
    console.log('file handler');

    uploadFile(selectedFile); // Call the callback function with the selected file

  };


  async function uploadFile(file) {
    try {
      // Generate a unique file name or use the original file name
      const fileName = `${Date.now()}-${record.name}`;

      // Upload the file to Supabase storage
      const { data, error } = await supabase.storage
        .from('tsuData/profileImage') // Specify the folder path where you want to upload the file
        .upload(fileName, file);

      if (error) {
        console.error('Error uploading file:', error.message);
        return null;
      }

      // Return the URL of the uploaded file
      return data.Key;
    } catch (error) {
      console.error('Error uploading file:', error.message);
      return null;
    }
  }


  const width = window.innerWidth; // Use window.innerWidth to get the current width


  const ViewDesktop = () => (
    <>
      <div className='w-full h-full flex flex-col justify-center items-center py-20 bg-black '>

        {isLoading && <><div className='absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center py-20 bg-black text-white'>Loading...</div></>}

        {!isLoading && record.length > 0 && <>
          
          <div className='rounded-full w-[15vw] h-[15vw] overflow-hidden flex items-center justify-center'>
          
            <img className='object-cover h-full w-full' src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${record[0].id}.jpg`} />

          </div>

          <p className='text-4xl font-bold text-white'>{record[0].name}</p>
          <p className='text-2xl text-center font-bold text-white'>@{record[0].username}</p>
          <p className='text-xl font-base text-white'>Blood group - {record[0].bloodGroup}</p>
          <p className='text-[3vw] font-bold text-white'>{record[0].bike}</p>
          <p className='text-[1.5vw] font-semibold text-white'>From {record[0].city}, {record[0].state}</p>


          <div className='w-[30%] h-full p-10 my-10 border-y border-gray-800 space-y-5 '>
          
            <p className='text-[1.5vw] font-thin text-orange-400 text-center'>about myself</p>
            <p className='text-[1.2vw]  text-white text-center'>in - {record[0].bio} </p>
          
          </div>

          <p className='text-[1.5vw] font-semibold text-white'>{record[0].email}</p>

        </>}

      </div>
    </>
  );





  const ViewMobile = () => (

    <div className='fixed w-full h-full overflow-scroll bg-black '>


      {record.length > 0 &&

        <div className='w-full pt-10 flex flex-col justify-center items-center'>

          <div className='w-[180px] h-[250px] border-0 flex justify-center items-center overflow-hidden'>
            <div className='rounded-full w-[180px] h-[180px] overflow-hidden'>
              <img src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${record[0].id}.jpg`} />
            </div>
          </div>

          <div className='w-[90%] flex flex-col justify-center items-center p-10 my-5 border-y border-gray-800 space-y-5 '>

            <p className='text-[7vw] text-center font-bold text-white'>{record[0].name}</p>
            <p className='text-[7vw] text-center font-bold text-white'>{record[0].username}</p>
            <p className='text-[3.5vw] font-base text-white'>Blood Group - {record[0].bloodGroup}</p>
            <p className='text-[3.5vw] font-bold text-white'>{record[0].bike}</p>
            <p className='text-[3.5vw] font-semibold text-white'>{record[0].city} | {record[0].state}</p>


            <p className='text-[5vw] font-thin text-orange-400 text-center'>About</p>
            <p className='text-[5vw]  text-white text-center'>{record[0].bio} </p>
          </div>

          <p className='text-[5vw] font-semibold text-white'>{record[0].email}</p>

        </div>}

      {/* <h2>Personal</h2>
      {Object.entries(record).map(([key, value]) => (
        <div key={key}>
          <p>{key}: {value}</p>
        </div>
      ))}

      <p>{data.id}nmnmm</p> */}
    </div>
  );





  return width >= 450 ? ViewDesktop() : ViewMobile();

};




export default MembersView;
