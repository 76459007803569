import React, { useContext } from 'react';
// import logo from './Data/Logo 2.png';
import { Link, useNavigate } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import MyContext from './Components/MyContext';





const Home = () => {

  const { token, setToken, handleLogout, gotoProfile } = useContext(MyContext);



  const navigate = useNavigate();

  const controls = useAnimation();

  const startAnimation = async () => {
    await controls.start({ opacity: 1, x: 0 });
    // await controls.start({ opacity: 0, x: -100 });
  };



  return (
    <div className=' fixed w-full h-full overflow-hidden bg-black'>



      <div className='flex w-full px-20 mt-20'>
        {/* <img className='object-cover ' src={logo} alt="logo" /> */}

      </div>


      <div className=''>


        <p class="text-white text-3xl text-center mt-3 mb-10 px-14 animate-fade-up animate-ease-linear" >
          Welcome to
        </p>


        <p class="text-white text-3xl text-center mt-3 mb-10 px-14 animate-fade-up animate-ease-linear" >
          <span className='text-[7vw] font-bold'>Two Stroke United</span>
        </p>

        <p class="text-white text-3xl text-center mt-3 mb-10 px-14 animate-fade-up animate-ease-linear" >
          <span className='text-[2vw]'>2 Stroke Biker's Social Network for India</span>
        </p>

        <p class="hover:text-white duration-300 text-md font-thin text-gray-600 text-center px-[250px] animate-fade-up animate-ease-linear" >
          Dive into the world where the revving engine is music to our ears and the smell of premix is our favorite perfume. This is your digital garage, built by enthusiasts for enthusiasts. Let's ignite the spark and ride the two-stroke wave together!
        </p>


      </div>


      {/* <div className=''>
        <p class="text-white text-sm text-center mt-10 px-20 animate-fade-up animate-ease-linear" >
        Two-stroke motorcycles, a vital part of India's biking history, emerged in the mid-20th century. 
        Brands like Yamaha, Suzuki, Rajdoot, and Yezdi introduced iconic models. The Yamaha RD350 in the 1980s 
        revolutionized the scene. Emission norms led to their discontinuation, but their legacy lives on in enthusiast circles.
        </p>
    </div> */}



      <div className=''>
        <p class="text-white text-3xl text-center mt-10 px-14 animate-fade-up animate-ease-linear" > Rev your engines</p>
        {token && <h3 className='text-white '>Welcome back, {token.user.user_metadata.full_name}</h3>}

      </div>

      <div class='w-full h-[100px] flex items-center justify-center mb-[100px]'>

        <button onClick={() => navigate('/signup')} class=' text-white w-[250px] h-[50px] border-2 border-[#9e613e] hover:border-whte duration-300 hover:bg-[#9e613e] mx-auto rounded-full' >JOIN</button>


      </div>



    </div>
  );
};

export default Home;
