import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MyContext from "./MyContext";
import EditProfile from '../supaBase/EditProfile';
import InsertPost from '../posts/InsertPost';
// import logo1 from '../public/Logo1.png';


const Navbar = () => {


    const { token, setToken } = useContext(MyContext);


    if (token) {
        sessionStorage.setItem('token', JSON.stringify(token))
    }

    useEffect(() => {
        if (sessionStorage.getItem('token')) {
            let data = JSON.parse(sessionStorage.getItem('token'))
            setToken(data)
        }

    }, [])


    return (
        <>
            {token ? <NavbarAuth /> : <NavbarPublic />}
        </>
    );
};







const NavbarAuth = () => {


    const navigate = useNavigate();
    const { username1, token, setToken, handleLogout, NavigateButton } = useContext(MyContext);

    const [menu, setMenu] = useState(false);





    const icon = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
        <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
    </svg>;


    const menuIcon = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
    </svg>



    return (

        <div class=" text-xl border-b border-gray-900 text-white w-full h-[70px] fixed top-0 left-0 backdrop-blur-xl flex items-center space-x-5 z-50">

            <img onClick={() => navigate('/')} className='cursor-pointer w-[25%] mx-5' src={process.env.PUBLIC_URL + "./TSU.png"} alt="TSU-Logo" />


            <div className='w-[70%] flex space-x-5'>
                {/* {token && <h3 className='font-thin text-[5vw] text-orange-300'>@{username1 && username1}</h3>} */}
            </div>

            <div className='w-[35%] bg-slate-000 flex itemas-center justify-center px-10 space-x-5'>

                <NavigateButton path='/explore' name='Explore' />

                <button className='text-white' onClick={() => { setMenu(prevState => !prevState) }}>{menuIcon}</button>

            </div>


            {menu &&

                <div className='animate-fade-down ease-in-out absolute top-20 right-5 p-8 rounded-xl flex flex-col space-y-5  bg-black border-2 border-gray-500'>

                    <NavigateButton path='/profile' name='Profile' />

                    <NavigateButton path='/users' name='View Members' />

                    <button className='text-base flex items-center space-x-5 text-ornage-300' onClick={handleLogout}>Logout {icon}</button>

                </div>

            }

        </div>

    );
};














const NavbarPublic = () => {


    const { NavigateButton } = useContext(MyContext);

    const navigate = useNavigate();



    const icon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
        <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
    </svg>;




    return (

        <div class=" text-md text-gray-500 duration-200 w-full h-[70px] fixed top-0 left-0 backdrop-blur-xl flex justify-end px-10 items-center space-x-5 z-50">

            <img onClick={() => navigate('/')} className='cursor-pointer w-[8%] mx-5' src={process.env.PUBLIC_URL + "./TSU.png"} alt="TSU-Logo" />


            {/* <NavigateButton path='/about' name='About TSU' /> */}
            <NavigateButton path='/login' name='Login' />
            <NavigateButton path='/signup' name='Signup' />

        </div>
    );
};









export default Navbar;
