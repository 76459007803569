import React, { useState, useContext, useEffect } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";






const InsertPost = () => {


    const [uploading, setUploading] = useState(false);
    const { token, Refresh, refresh } = useContext(MyContext)
    const [username1, setUsername1] = useState('');



    useEffect(() => {
        // Access username1 from localStorage when component mounts or updates
        const storedUsername1 = localStorage.getItem('username1');
        console.log('postid', storedUsername1);

        if (storedUsername1) {
            setUsername1(storedUsername1);
            console.log('postid', username1);
        }
    }, []);

    //   console.log( 'insert lulu',username1);

    const storedUsername1 = localStorage.getItem('username1');
    //   console.log( 'insert lulubybubub',storedUsername1);


    const [post, setPost] = useState({
        user_id: token.user ? token.user.id : '', // Check if token.user exists
        post: '',
        username: storedUsername1
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setPost({ ...post, [name]: value });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { data, error } = await supabase
                .from('posts')
                .insert(post)
                .select()
            if (error) {
                console.error("Failed to insert data:", error.message);
                return;
            }

            console.log("Data inserted successfully:", data);
            // Clear the form after successful submission
            setPost({
                user_id: token.user.id,
                post: '',
                username: storedUsername1
            });
            Refresh();
            console.log('data done')
            setUploading(true);
        } catch (error) {
            console.error("Error inserting data:", error.message);
        }
    };


    const width = window.innerWidth; // Use window.innerWidth to get the current width


    // const handleUpload = async (e) => {

    //     setUploading(true);

    //     const avatarFile = e.target.files[0]

    //     try {
    //         const { data, error } = await supabase
    //             .storage
    //             .from('tsuData')
    //             .upload(`profileImage/avatar${record[0].id}.jpg`, avatarFile, {
    //                 upsert: true
    //             })

    //         if (error) {
    //             console.error("Failed to upload:", error.message);
    //             setUploading(false);
    //             return;
    //         }
    //         console.log("Data inserted successfully:", data);
    //         setUploading(false);

    //     } catch (error) {
    //         console.error("Error inserting data:", error.message);
    //     }
    // };





    const InsertDesktop = () => (

        <div className="w-full h-full">

        {uploading &&

            <div className=" absolute top-0 left-0 w-screen h-screen bg-black/80 flex justify-center items-center z-40">

                <div className="animate-fade-up space-y-5 w-[30%] h-[30%] bg-black rounded-3xl border border-gray-500 flex flex-col justify-center items-center">
                    <p className="text-white text-3xl">Posted Successfuly</p>
                    <button className="text-white" onClick={() => setUploading(false)}>close</button>
                </div>
            </div>
        }

        <div className="w-full h-full bg-gray-000 flex space-x-10 justify-center items-center">

            {/* <h1 className="text-xl font-bold my-5 text-white">Share Pos</h1> */}

            <form onSubmit={handleSubmit} className="flex items-center w-[80%] space-x-2">



                <input
                    className="p-2 w-[100%] rounded-full"
                    type="text"
                    placeholder="Type..."
                    name='post'
                    value={post.post}
                    onChange={handleChange}
                    required
                />



                <button className="bg-orange-500 hover:bg-orange-600 rounded-full p-2 w-[30%]" type="Update">
                    Share Post
                </button>

            </form>

        </div>
    </div>

    );








    
    const InsertMobile = () => (

        <div className="w-full h-full">

        {uploading &&

            <div className=" absolute top-0 left-0 w-screen h-screen bg-black/80 flex justify-center items-center z-40">

                <div className="animate-fade-up space-y-5 w-[30%] h-[30%] bg-black rounded-3xl border border-gray-500 flex flex-col justify-center items-center">
                    <p className="text-white text-3xl">Posted Successfuly</p>
                    <button className="text-white" onClick={() => setUploading(false)}>close</button>
                </div>
            </div>
        }

        <div className="w-full h-full bg-gray-000 flex space-x-5 justify-center items-center">

            {/* <h1 className="text-xl font-bold my-5 text-white">Share Pos</h1> */}

            <form onSubmit={handleSubmit} className="flex items-center w-full space-x-2">



                <input
                    className="p-2 w-[100%] rounded-full"
                    type="text"
                    placeholder="Share with Union"
                    name='post'
                    value={post.post}
                    onChange={handleChange}
                    required
                />



                <button className="bg-orange-500 hover:bg-orange-600 rounded-full p-2 w-[35%]" type="Update">
                    Share
                </button>

            </form>

        </div>
    </div>

    );










    return width >= 450 ? InsertDesktop() : InsertMobile();
};




export default InsertPost;
