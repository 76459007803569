import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../client';



const Login = ({ setToken }) => {


    let navigate = useNavigate()


    const [formData, setFormData] = useState({
        email: '', password: ''
    })


    function handleChange(event) {
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }

        })

    }

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: formData.email,
                password: formData.password,
            })

            if (error) throw error
            setToken(data);
            navigate('/profile');


            //   alert('Check your email for verification link')


        } catch (error) {
            alert(error);
        }
    }


    const width = window.screen.width;

    console.log(width);




    return (
        <div className='fixed bg-black w-full h-screen flex flex-col justify-center items-center text-white'>

            <div className='fixed bg-slate-000 w-[80%] h-full flex flex-col justify-center items-center overflow-hidden'>

                <form onSubmit={handleSubmit} className='w-full space-y-5 h-full flex flex-col items-center justify-center'>

                    <input

                        className='p-3 text-gray-900 w-[80%] rounded-full'
                        placeholder='Email'
                        name='email'
                        onChange={handleChange}
                    />

                    <input

                        className='p-3 text-gray-900 w-[80%] rounded-full'

                        placeholder='Password'
                        name='password'
                        type="password"
                        onChange={handleChange}
                    />

                    <button type='submit' className='px-10 py-3 bg-orange-600 hover:bg-orange-500 rounded-full'>
                        Submit
                    </button>


                    <p className=''> Don't have an account? </p>

                    <Link className='text-orange-300' to='/signup'>Sign Up</Link>

                </form>




            </div>

        </div>
    )
}

export default Login