import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../../client';
import MyContext from '../Components/MyContext';
import MechanicInsertPost from './MechanicInsertPost';
import MechanicProfileEdit from './mechanicProfileEdit';
import { formatDistanceToNow } from 'date-fns';






const MechanicProfileMobile = () => {


    const { userId } = useParams();
    const navigate = useNavigate();
    const [record, setRecord] = useState([]);
    const { Refresh, refresh, username1 } = useContext(MyContext);
    const [edituser, setEdituser] = useState(false);




    useEffect(() => {
        async function fetchUserData() {
            const { data, error } = await supabase
                .from('mechanic')
                .select('*')
                .filter('username', 'eq', userId);

            if (error) {
                console.error('Failed to fetch user data:', error.message);
                return;
            }

            if (data && data.length > 0) {
                setRecord(data);
            }
        }

        fetchUserData();
    }, [userId, refresh]);




    return (
        <div className='w-full h-full flex flex-col overflow-x-scroll px-10 pb-20 justify-start items-center bg-black fixed py-20'>


            {edituser && <MechanicProfileEdit record={record} setEdituser={setEdituser} />}

            <div className='w-full  flex flex-col justify-center items-center  bg-gray-500/20 rounded-xl  h-max'>

                {record.length > 0 && (


                    <div className='w-full space-y-2 h-max flex flex-col justify-center items-center py-5'>

                        <div className='rounded-full bg-white w-[150px] h-[150px] overflow-hidden'>
                            {/* <img src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${record[0].id}.jpg`} /> */}
                        </div>

                        <p className='text-3xl font-bold text-white text-wrap break-words'>{record[0].shopName}</p>
                        <p className='text-md font-bold text-white text-wrap break-words'>by {userId}</p>
                        <p className='text-sm font-semibold text-white'>{record[0].city}, {record[0].state}, {record[0].country}</p>
                        <p className='text-sm text-white text-center'>{record[0].bio}</p>
                        <p className='text-sm text-white text-center'>{record[0].mobile}</p>
                        <p className='text-sm text-white text-center'>{record[0].email}</p>
                        <button className='bg-blue-500 p-3 rounded-lg text-white font-bold'>Follow</button>


                        {username1 === record[0].username &&
                            <button className='text-white mb-10 hover:text-orange-500' onClick={() => { setEdituser(prevState => !prevState) }}>Edit Profile</button>
                        }

                    </div>
                )}

            </div>

                    <MechanicInsertPost username={userId} />
                <div className='flex flex-col overflow-x-scroll h-[90%]'>
                    {record.length > 0 && record[0].posts && record[0].posts.map((member, index) => (
                        <PostsView key={index} data={member} index={index} posts={record[0].posts} username={userId} />
                    ))}
                </div>

        </div>
    );
}






function PostsView({ data, index, posts, username }) {

    const { Refresh, username1 } = useContext(MyContext);
    const navigate = useNavigate();

    const [timeAgo, setTimeAgo] = useState('');

    const { post, postBy, dateTime } = typeof data === 'string' ? JSON.parse(data) : data;





    const handleRemove = async () => {
        try {
            const updatedPosts = posts.filter((_, i) => i !== index);

            const { data, error } = await supabase
                .from('mechanic')
                .update({ posts: updatedPosts })
                .eq('username', username);

            if (error) {
                console.error("Failed to delete/update posts:", error.message);
                return;
            }

            Refresh();
            console.log('Post removed successfully');
        } catch (error) {
            console.error("Error removing data:", error.message);
        }
    };





    useEffect(() => {
        const updateTime = () => {
            setTimeAgo(formatDistanceToNow(new Date(dateTime), { addSuffix: true }));
        };

        // Update the time every minute
        const intervalId = setInterval(updateTime, 60000);

        // Set the initial time
        updateTime();

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [dateTime]);







    return (
        <div className='w-full flex flex-col border border-gray-900 my-3 p-5 rounded-xl'>
            <p className='text-white text-lg font-semibold'>{post}</p>
            
            <div className='flex mt-2 space-x-1'>
                <p className='text-gray-400 text-xs'>
                    Post by:
                    <span className='text-orange-400 hover:text-orange-200 px-2'>
                        <button onClick={() => navigate(`/${postBy}`)}>{postBy}</button>
                    </span>
                </p>
                <p className='text-gray-400 text-xs'>on: {timeAgo}</p>
                {postBy === username1 &&
                    <button className="bg-gray-800 hover:bg-red-600 text-white text-xs px-2  rounded" onClick={handleRemove} > Remove </button>
                }

            </div>
        </div>
    );
}

export default MechanicProfileMobile;
