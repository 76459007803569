import React, { useState, useEffect, useContext } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";




const AddNewProfile = ({ userId }) => {


    const [stage, setStage] = useState({ name: true, location: false, blood: false, username: false })

    const { token, username1, Refresh, refresh } = useContext(MyContext);

    const navigate = useNavigate();

    const [exist, setExist] = useState({ exists: false, username: '' });
    const [names, setNames] = useState([]);
    const [indicator, setIndicator] = useState({ dataEmpty: false, ProfileCreated: false});


    const [userInfo, setUserInfo] = useState({
        user_id: userId,
        name: '',
        city: '',
        state: '',
        bloodGroup: '',
        username: ''
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };



    const refreshPage = () => {
        window.location.reload();
      };




    const handleSubmit = async (e) => {
        e.preventDefault();


        if (userInfo.name === '' || userInfo.city === '' || userInfo.bloodGroup === '') {
            setIndicator({dataEmpty:true})
         alert('Some Field are empty');
            return;
        }

        try {
            const { data, error } = await supabase
                .from('user')
                .insert(userInfo);

            if (error) {
                console.error("Failed to insert data:", error.message);
                return;
            }

            console.log("Data inserted successfully:", data);
            // Clear the form after successful submission
            setUserInfo({
                user_id: userId,
                name: '',
                city: '',
                state: '',
                bloodGroup: '',
                username: username1,
            });
            setIndicator({ProfileCreated:true})
            refreshPage();

        } catch (error) {
            console.error("Error inserting data:", error.message);
        }
    };





    useEffect(() => {
        async function fetchUsernames() {
            const { data, error } = await supabase
                .from('userName')
                .select();

            if (error) {
                console.error('Error fetching usernames:', error);
            } else {
                setNames(data);
                const usernameExists = names.some(member => member.user_id === token.user.id);
                setExist(usernameExists);
            }
        }

        fetchUsernames();

    }, []);

    





    return (
        <div className="absolute w-full h-full bg-black flex flex-col items-center justify-start">


            <div className="h-[40%] border-0 w-full flex flex-col justify-center items-center">

                <h2 className="text-white text-5xl">Setup Your Profile</h2>

                <div className="w-fit mt-9 flex space-x-9 relative">

                    <p className={` ${stage.name && 'text-white text-xl border-2 border-orange-500 px-5 py-1 rounded-full duration-500'} duration-500 text-gray-700 cursor-pointer text-xl`} onClick={() => setStage({ name: true })}>Display Name</p>
                    <p className={` ${stage.location && 'text-white text-xl border-2 border-orange-500 px-5 py-1 rounded-full duration-500'} duration-500 text-gray-700 cursor-pointer text-xl`} onClick={() => setStage({ location: true })}>Location</p>
                    <p className={` ${stage.blood && 'text-white text-xl border-2 border-orange-500 px-5 py-1 rounded-full duration-500'} duration-500 text-gray-700 cursor-pointer text-xl`} onClick={() => setStage({ blood: true })}>Blood Group</p>
                    <p className={` ${stage.username && 'text-white text-xl border-2 border-orange-500 px-5 py-1 rounded-full duration-500'} duration-500 text-gray-700 cursor-pointer text-xl`} onClick={() => setStage({ username: true })}>Username</p>

                </div>

            </div>


            <form onSubmit={handleSubmit} className="flex w-full justify-center items-center">






                {stage.name &&
                    <div className="w-[50%] border-0 animate-fade-left ease-in-out delay-100 text-orange-500 text-3xl font-bold">
                        <input
                            type="text"
                            placeholder="Display Name"
                            name='name'
                            value={userInfo.name}
                            onChange={handleChange}
                            required
                            className="mr-5 w-[70%]  text-lg rounded-full p-3 outline-none focus:outline-9 focus:outline-orange-500"
                        />
                        <button onClick={() => setStage({ location: true })} className="w-[27%] bg-orange-500 p-3 rounded-full text-white text-lg">Save and Proceed</button>
                    </div>
                }


                {stage.location &&
                    <div className="w-[50%] border-0 animate-fade-left ease-in-out delay-100 text-orange-500 text-3xl font-bold">
                        <input
                            type="text"
                            placeholder="City"
                            name='city'
                            value={userInfo.city}
                            onChange={handleChange}
                            required
                            className="mr-5 w-[70%]  text-lg rounded-full p-3 outline-none focus:outline-9 focus:outline-orange-500"
                        />
                        <button onClick={() => setStage({ blood: true })} className="w-[27%] bg-orange-500 p-3 rounded-full text-white text-lg">Save and Proceed</button>
                    </div>
                }

                {stage.blood &&
                    <div className="w-[50%] border-0 animate-fade-left ease-in-out delay-100 text-orange-500 text-3xl font-bold">
                        <input
                            type="text"
                            placeholder="Your Blood Group"
                            name='bloodGroup'
                            value={userInfo.bloodGroup}
                            onChange={handleChange}
                            required
                            className="mr-5 w-[70%]  text-lg rounded-full p-3 outline-none focus:outline-9 focus:outline-orange-500"
                        />
                        <button onClick={() => setStage({ username: true })} className="w-[27%] bg-orange-500 p-3 rounded-full text-white text-lg">Save and Proceed</button>
                    </div>
                }



                {stage.username &&

                    <div className="w-[50%] border-0 animate-fade-left ease-in-out delay-100 text-orange-500 flex flex-col justify-center">
                        <UsernameDefine names={names} setUserInfo={setUserInfo} userInfo={userInfo} />

                        <button type="submit" className="bg-orange-500 p-5 mx-[25%] rounded-full text-white text-lg">Save and Create Profile</button>
                    </div>
                }


            </form>

                {indicator.ProfileCreated && 
                

                    <div className=" absolute top-0 left-0 w-screen h-screen bg-black/80 flex justify-center items-center z-40">
    
                        <div className="animate-fade-up space-y-5 w-[30%] h-[30%] bg-black rounded-3xl border border-gray-500 flex flex-col justify-center items-center">
                            <p className="text-white text-3xl">Profile Created Successfuly</p>
                        </div>
                    </div>
                }

        </div>
    );
};















function UsernameDefine({ names, setUserInfo, userInfo }) {


    const [indicator, setIndicator] = useState({ available: false, upload: false });
    const [newUsername, setNewUsername] = useState({ username: '', user_id: '' });


    const { token } = useContext(MyContext);


    function handleChange(e) {

        const { value } = e.target;
        setNewUsername((prev) => ({ ...prev, username: value, user_id: token.user.id }));
        const usernameExists = names.some(member => member.username === value);
        if (usernameExists) { setIndicator({ available: true }); }
        else {

            setIndicator({ available: false });
            setUserInfo({ ...userInfo, username: value });
        }
    }



    async function uploadName() {
        const { data, error } = await supabase
            .from('userName')
            .insert(newUsername);

        const { data2, error2 } = await supabase
            .from('user')
            .update({ username: newUsername.username })
            .eq('user_id', token.user.id);


        if (error) {
            console.error('Error fetching usernames:', error);
        } else {
            console.log('data:', data);
            setIndicator({ upload: true })
            console.error('username created');

        }
    }



    const okIcon = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
  </svg>

    return (
        <div className="flex flex-col w-full justify-center items-center">

            <div className="flex w-full justify-center items-center space-x-3">

                <input
                    className={`p-2 rounded-full text-white ${indicator.upload && 'hidden'} ${indicator.available ? 'ring-2 ring-red-500' : 'ring-2 ring-green-500'} outline-none bg-gray-400/10 border-1 placeholder-gray-600 px-3`}
                    type="text"
                    placeholder="username"
                    name="username"
                    value={newUsername.username}
                    onChange={handleChange}
                    required
                />

                { indicator.available ? <p className="text-red-500">Username not available</p> : <button className={`bg-green-500 py-2 px-5 rounded-full text-black ${indicator.upload && 'hidden'}`} onClick={uploadName}>Create Username</button>}
                { indicator.upload && <div className="bg-green-500 py-2 px-5 my-5 rounded-full text-black flex items-center space-x-3" > <p> Username Created </p> {okIcon} </div>}


            </div>

            <p className={`${indicator.upload && 'hidden my-5'} py-5 text-gray-200`} >You can create and set username only once.</p>

        </div>
    );
}









export default AddNewProfile;
