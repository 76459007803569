import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import MyContext from '../Components/MyContext';
import MarketInsertPost from './MarketInsertPost';
import { supabase } from '../../client';
import Delete from '../posts/Delete'
import { formatDistanceToNow } from 'date-fns';





const MarketHome = () => {



    // const supabase = createClient("https://yjqfvaqpwdiblozmnruy.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlqcWZ2YXFwd2RpYmxvem1ucnV5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ4MjQ0ODQsImV4cCI6MjAzMDQwMDQ4NH0.lKlUqGH7wJJCQatUBMUikAtRYH5LjuX41XSwXoYuqJ8");

    const [record, setRecord] = useState([]);
    const [user, setUser] = useState();
    const [filterby, setFilterby] = useState({ all: true, city: false, state: false, club: false, friends: false, search: false });
    
    const { username1, Refresh, token, refresh, userData } = useContext(MyContext);
    
    const [search, setSearch] = useState('');


    useEffect(() => {
        getCountries();
    }, []);

    async function getCountries() {
        const { data } = await supabase.from("market").select();
        setRecord(data);
    }



    



    useEffect(() => {
        getData();
    }, [token]);

    async function getData() {
        const { data, error } = await supabase
            .from('market')
            .select('*')
        console.log('user', data);

        setUser(data);
    }


    function handleSearch(e) {

        setSearch(e.target.value);
        setFilterby({ search: true });

    }

    return (
        <div className="fixed w-full h-screen bg-black overflow-scroll grid grid-flow-col grid-col-8">

            <div className='span-col-2 bg-slate-500/10 px-10 pt-10 space-y-5 flex flex-col justify-start items-start'>

                <p className='text-orange-400 text-5xl mb-5'>Exploreeeee</p>


                <input
                    className="p-2 w-[100%] rounded-full border outline-none"
                    type="text"
                    placeholder="Search..."
                    name='search'
                    value={search}
                    onChange={handleSearch}
                />

                <p className='text-white'>{search}</p>
                <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ city: true })}>By my city </button>
                <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ state: true })}>By my state</button>
                <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ all: true })}>clear filter</button>

            </div>



            {user &&

                <div className='flex flex-col'>

                    <div>

                        <MarketInsertPost/>
                    
                    </div>


                    <div className="span-col-4 grid grid-cols-3 gap-5 px-20 place-content-start py-20">

                        {filterby.all && record.map((member, index) => <MemberCardMobile key={index} data={member} />)}
                        {filterby.city && record.filter(member => member.city === userData[0].city).map((member, index) => (<MemberCardMobile key={index} data={member} />))}
                        {filterby.state && record.filter(member => member.state === userData[0].state).map((member, index) => (<MemberCardMobile key={index} data={member} />))}
                        {filterby.search && record.filter(member => member.post.toLowerCase().includes(search.toLowerCase())).map((member, index) => (<MemberCardMobile key={index} data={member} />))}

                    </div>

                </div>

            }

        </div>
    );
};














const MemberCardMobile = (props) => {

    const {market_id, post, state, city, price, username, image, created_at, type } = props.data;

    const navigate = useNavigate();

    const [timeAgo, setTimeAgo] = useState('');


    const { username1, Refresh, token, refresh, userData } = useContext(MyContext);




    useEffect(() => {
        const updateTime = () => {
            setTimeAgo(formatDistanceToNow(new Date(created_at), { addSuffix: true }));
        };

        // Update the time every minute
        const intervalId = setInterval(updateTime, 60000);

        // Set the initial time
        updateTime();

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [created_at]);





    const handleDelete = async () => {

        try {

            const { error } = await supabase
                .from('market')
                .delete()
                .eq('market_id', market_id)

            if (error) {
                console.error("Failed to delete bike data:", error.message);
                return;
            }

            // setSuccess(true);
            Refresh();
            
            console.log('delete done')

            setTimeout(() => {
                // setPrompt(false);
            }, 5000);

        } catch (error) {
            console.error("Error deleting data:", error.message);
        }
    };





    return (

        <div className="cursor-pointer rounded-2xl flex flex-col items-center overflow-hidden h-[350px] space-x-3 p-2 bg-gray-500/10 hover:border-gray-800 duration-300" >

            <div className="bg-red-600 border h-auto w-full flex overflow-hidden" >
                <img className='object-cover w-full flex' src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/marketImages/${image}?c=1`} />
            </div>

            <div className='text-left space-y-2 bg-gray-000'>
                <p className="text-white text-md lg:text-lg m-auto" >{post}</p>
                <p className="text-white text-xs lg:text-sm">Location: {city}, {state}</p>
                <p className="text-white text-xs lg:text-sm">Price:{price}</p>
                <p className="text-white text-xs lg:text-sm">For: {type}</p>
            </div>

            <div className='text-left space-y-2 bg-gray-000 border-y'>
            <p className="text-white text-xs lg:text-sm">Posted by: @{username}</p>
            <p className="text-white text-xs">{timeAgo}</p>
            <button id={market_id} table='market' item={type} onClick={handleDelete}>Remove</button>

            </div>


        </div>
    );
};






export default MarketHome;
