import React, { useState, useContext, useEffect } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";
import NotificationInsert from "./NotificationInsert";












const InsertLike = (post_id) => {



    const { token, Refresh, refresh, username1 } = useContext(MyContext);
    const [likesdata, setLikesdata] = useState([]);



    const extractPostId = (obj) => obj.post_id;
    const postId = extractPostId(post_id);




    const [post, setPost] = useState({
        user_id: token.user ? token.user.id : '', // Check if token.user exists
        username: username1,
        post_id: postId
    });







    useEffect(() => {
        const fetchLikedata = async () => {
            try {
                const { data, error } = await supabase
                    .from('likes')
                    .select()
                    .eq('post_id', postId); // Filter by post_id
                if (error) {
                    console.error("Failed to fetch data:", error.message);
                    return;
                }
                setLikesdata(data);
                console.log("Likes Added");

            } catch (error) {
                console.error("Error fetching data:", error.message);
            }
        };

        fetchLikedata();
    }, [post_id, refresh]);




    const handleSubmit = async () => {
        try {
            const { data, error } = await supabase
                .from('likes')
                .insert(post)
                .select();
            if (error) {
                console.error("Failed to insert Like:", error.message);
                return;
            }

            console.log("Like inserted successfully:", data);
            
            //Notification Insert call - username, post_id, table name, table column, notification text
            NotificationInsert(username1, postId, 'posts', 'username', 'liked your post');

            Refresh();
             
            console.log('data done');
        } catch (error) {
            console.error("Error inserting Like:", error.message);
        }
    };


    const handleUnlike = async () => {
        try {
            const { data, error } = await supabase
                .from('likes')
                .delete()
                .eq('post_id', postId)
                .eq('username', username1);
            if (error) {
                console.error("Failed to unlike:", error.message);
                return;
            }

            console.log("Unlike successful:", data);
            
            Refresh();
            
            console.log('data done');

        } catch (error) {
            console.error("Error unliking:", error.message);
        }
    };



    const userHasLiked = likesdata.some(like => like.post_id === postId && like.username === username1);



    const unlikeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
    </svg>;


    const likeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
    </svg>



    return (
        <div className="flex items-center justify-center">
            {userHasLiked ? (

                <button onClick={handleUnlike} className=" px-2 text-orange-500">
                    {likeIcon}
                </button>


            ) : (
                <button onClick={handleSubmit} className=" px-5 text-orange-500">
                    {unlikeIcon}
                </button>
            )}

            {likesdata && <p className="text-white">{likesdata.length}</p>}

        </div>
    );
};

export default InsertLike;
