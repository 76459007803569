// MyContext.js
import React, { createContext, useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../client';



const MyContext = createContext();



export const MyContextProvider = ({ children }) => {
  
    const [token, setToken] = useState('');
    
    const [username1, setUsername1] = useState('');
    
    const [userData, setUserData] = useState('');


    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();
    
    const [uid, setUid] = useState('');


    function Refresh(){
    setRefresh(prevState => !prevState)
    }


    







    async function handleLogout(){
      
      try{
      let { error } = await supabase.auth.signOut();
      sessionStorage.removeItem('token');
      setToken('');
      navigate('/');
      console.log('signout');
      localStorage.clear();

    }
    catch(error){
      console.error(error);
    }
    }
    









    function gotoProfile(){ navigate('/profile');}



    function NavigateButton({path, name}){
    
      return(
          <>
          <button className='hover:text-gray-200 text-gray-500 duration-300 text-base ' onClick={()=>navigate(path)}>{name}</button>
          </>
      );
    }



  return (
    <MyContext.Provider value={{ userData, setUserData, username1, setUsername1, Refresh, refresh, handleLogout, gotoProfile, token, setToken, NavigateButton}}>
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
