import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import InsertPost from '../posts/InsertPost';
import MyContext from '../Components/MyContext';
import { supabase } from '../../client';
import AddasFriend from '../functions/AddasFriend.jsx';
import PostHome from '../posts/PostHome';
import ViewRide from '../Ride/ViewRide';






const UserHome = ({username1}) => {


    const [record, setRecord] = useState([]);
    const {setUsername1, Refresh, token, refresh } = useContext(MyContext)
    const [show, setShow] = useState({ posts: true, ride: false })


    const navigate = useNavigate();

    useEffect(() => {

        getData();

    }, [refresh]);

    async function getData() {
        const { data } = await supabase.from("posts").select();
        setRecord(data);
    }


    useEffect(() => {
        // Access username1 from localStorage when component mounts or updates
        const storedUsername1 = localStorage.getItem('username1');
        if (storedUsername1) {
          setUsername1(storedUsername1);
          console.log('Stored Username from localStorage:', storedUsername1);
          console.log('State Username:', username1);
        }
      }, []);



    // { record && console.log(record); }

    return (
        <div className="fixed w-full h-full bg-black px-10 overflow-hidden">



            <div className='h-fit flex flex-col w-full items-center border-b-0 border-gray-000 my-2'>


                <div className='flex w-full items-center'>
                    <p className='text-2xl w-[70%] text-gray-100 font-semibold'>Explore Activities by Riders, Clubs, communities !!</p>

                    <InsertPost />
                </div>

                <div className='rounded-full border border-gray-200 space-x-10 flex text-2xl items-center justify-center w-full py-3 bg-gray-500/20'>
                    <p className='text-white font-bold '>Explore</p>
                    <button className='text-white px-5 focus:outline-none focus:ring focus:ring-orange-500 rounded-full duration-500' onClick={() => setShow({ posts: true })}>Posts</button> 
                    <button className='text-white px-5 focus:outline-none focus:ring focus:ring-orange-500 rounded-full duration-500' onClick={() => setShow({ ride: true })}>Rides</button> 
                    <button className='text-white px-5 focus:outline-none focus:ring focus:ring-orange-500 rounded-full duration-500' onClick={() => navigate('/users')}>People</button> 
                    <button className='text-white px-5 focus:outline-none focus:ring focus:ring-orange-500 rounded-full duration-500' onClick={() => navigate('/market')}>Market</button> 
                    <button className='text-white px-5 focus:outline-none focus:ring focus:ring-orange-500 rounded-full duration-500' onClick={() => navigate('/mechanics')}>Mechanics</button> 

                </div>

            </div>

            {show.posts && <PostHome />}
            {show.ride && <ViewRide username={username1}/>}


        </div>
    );
};















const PostView = (props) => {

    const { post, uid, id, created } = props.data;

    return (

        <div className="animate-fade-up rounded-2xl flex justify-center py-10 space-x-5 animate-ease-linear p-2  border border-gray-800 hover:border-orange-500 duration-1000" >

            {/* <div className="bg-red-600 col-span-2 border w-[30%] rounded-full overflow-hidden" > 
        
        <img className='object-cover' src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${id}.jpg`}/>
        
        </div> */}


            <div className='flex flex-col justify-center items-center space-y-3 bg-red-000 w-[80%] text-center'>

                <div className='h-[85%] bg-slate-000 w-full'>
                    <p className="text-white text-xs lg:text-3xl">{post} </p>
                    <p className="text-white text-xs lg:text-sm">{uid}</p>
                </div>
                <div className='h-[15%] flex justify-center items-center space-x-10 bg-slate-000 w-full'>
                    <p className="text-gray-700 text-md lg:text-sm m-auto my-5 flex" >Posted on: {created.slice(0, 10)}</p>
                    <button className='text-gray-500 hover:text-orange-300'>Add as Friend</button>
                </div>
            </div>

        </div>
    );
};





export default UserHome;
