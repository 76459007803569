import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import MyContext from '../Components/MyContext';
import { supabase } from '../../client';

const Mechanics = () => {

  const [record, setRecord] = useState([]);
  const [user, setUser] = useState();
  const [filterby, setFilterby] = useState({ all: true, city: false, state: false, club: false, friends: false });
  const { username1, Refresh, token, refresh } = useContext(MyContext);


  useEffect(() => {
    getCountries();
  }, []);

  async function getCountries() {
    const { data } = await supabase.from("mechanic").select();
    setRecord(data);
  }

  console.log(record);


  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    const { data, error } = await supabase
      .from('user')
      .select('*')
      .filter('user_id', 'eq', token && token.user.id); // Filter by ID
    console.log('user', data);

    setUser(data);
  }


  return (
    <div className="fixed w-full h-screen bg-black overflow-scroll grid grid-flow-col grid-col-8">

      <div className='span-col-2 bg-slate-500/10 px-10 pt-10 space-y-5 flex flex-col justify-start items-start'>

        <p className='text-orange-400 text-5xl mb-5'>Explore</p>

        <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ city: true })}>By my city </button>
        <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ state: true })}>By my state</button>
        {/* <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500'>By Country</button>  */}
        <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ club: true })}>By my Club</button>
        <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ friends: true })}>By my Friends</button>
        <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ all: true })}>clear filter</button>

      </div>

      <div className="span-col-6 grid grid-cols-1 gap-5 bg-black px-20">


        {record.map((member, index) => (<Link to={`/mechanics/${member.username}`}>   <MemberCardMobile key={index} data={member} /> </Link>))}

        {/* {filterby.all && record.map((member, index) => (<Link to={`/${member.username}`}>   <MemberCardMobile key={index} data={member} /> </Link>))} */}
        {/* {filterby.city && record.filter(member => member.city === user[0].city).map((member, index) => (<Link to={`/${member.username}`} key={index}> <MemberCardMobile data={member} /> </Link>))}
        {filterby.state && record.filter(member => member.state === user[0].state).map((member, index) => (<Link to={`/${member.username}`} key={index}> <MemberCardMobile data={member} /> </Link>))}
        {filterby.club && record.filter(member => member.club === user[0].club).map((member, index) => (<Link to={`/${member.username}`} key={index}> <MemberCardMobile data={member} /> </Link>))}
        {filterby.friends && record.filter(member => member.friends === user[0].username).map((member, index) => (<Link to={`/${member.username}`} key={index}> <MemberCardMobile data={member} /> </Link>))} */}

      </div>

    </div>
  );
};














const MemberCardMobile = (props) => {

  const { shopName, address, bio, state, city, id,experience } = props.data;

  return (

    <div className="animate-fade-up rounded-2xl flex overflow-hidden space-x-5 animate-ease-linear p-2  border border-gray-800" >

      <div className="bg-red-600 col-span-2 border w-[130px] h-[130px] rounded-full overflow-hidden" >

        <img className='object-cover' src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${id}.jpg`} />

      </div>

      <div className='text-left col-span-4 space-y-2 bg-red-000 w-[70%]'>

        <p className="text-white text-md font-semibold lg:text-3xl m-auto" >{shopName}</p>
        <p className="text-white text-xs lg:text-lg">Location: {address}, {city}, {state} </p>
        <p className="text-white text-xs lg:text-sm"></p>
        <p className="text-white text-xs lg:text-sm">{bio}</p>
        <p className="text-white text-xs lg:text-xl">Experience: {experience}</p>

      </div>

    </div>
  );
};



export default Mechanics;
