import React, { useState, useEffect, useContext } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";
import { useNavigate } from "react-router-dom";




const MechanicProfileCreate = () => {

    const [show, setShow] = useState(false);

    const {userData, username1} = useContext(MyContext);

    const navigate =useNavigate();

    console.log('inserdata', userData);

    return (

        <>

        {userData && ( userData[0].mechanic != username1 ? <button className='text-white hover:bg-orange-600 bg-orange-500 px-5 p-1 rounded-full' onClick={() => setShow(true)}> Create Mechanic Profile </button>
            
            :
            <button className='text-white hover:bg-orange-600 bg-orange-500 px-5 p-1 rounded-full' onClick={() => navigate('/mechanics/'+username1)}> Mechanic Profile </button>

        )}    
            {show && <ProfileCreate setShow={setShow}/>}

        </>

    );

}






const ProfileCreate = ({setShow}) => {


    const { token, username1} = useContext(MyContext);

    const navigate = useNavigate();

    const [uploading, setUploading] = useState(false);


    const [userInfo, setUserInfo] = useState({
        user_id: token.user.id,
        // username: record[0].username,
        profileImage: '',
        shopName: '',
        city: '',
        state: '',
        country: '',
        experience: '',
        bio: '',
        contact: '',
        email: '',
        address: '',
        username: username1,
        services: []
    });





    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };







    const handleSubmit = async (e) => {

        e.preventDefault();

        try {
            const { data, error } = await supabase
                .from('mechanic')
                .insert(userInfo)

                const { error2 } = await supabase
                .from('user')
                .update({mechanic: username1 ? username1 :''})
                .eq('username', username1)


            if (error) {
                console.error("Failed to insert data:", error.message);
                return;
            }

            console.log("Data inserted successfully:", data);
            // Clear the form after successful submission
            setUserInfo({
                user_id: token.user.id,
                // username: record[0].username,
                profileImage: '',
                shopName: '',
                city: '',
                state: '',
                country: '',
                experience: '',
                bio: '',
                contact: '',
                email: '',
                address: '',
                username: username1,
                services: []
            });
            setUploading(true);

        } catch (error) {
            console.error("Error inserting data:", error.message);
        }
    };




    // const handleUpload = async (e) => {

    //     setUploading(true);

    //     const avatarFile = e.target.files[0]

    //     try {
    //         const { data, error } = await supabase
    //             .storage
    //             .from('tsuData')
    //             .upload(`profileImage/avatar${record[0].id}.jpg`, avatarFile, {
    //                 upsert: true
    //             })

    //         if (error) {
    //             console.error("Failed to upload:", error.message);
    //             setUploading(false);
    //             return;
    //         }
    //         console.log("Data inserted successfully:", data);
    //         setUploading(false);

    //     } catch (error) {
    //         console.error("Error inserting data:", error.message);
    //     }
    // };






    return (
        <div className="absolute top-0 left-0 w-full h-full overflow-scroll bg-black py-20 backdrop-blur-xl">


            {/* <div className="fixed w-full h-full bg-slate-500 top-0 left-0"></div> */}

            {uploading ?

                <div className="w-full h-full flex justify-center items-center">

                    <div className="space-x-10 w-[50%] h-[50%] bg-black rounded-3xl border border-gray-500 flex justify-center items-center">
                        <p className="text-white text-3xl">Profile Updated</p>
                        {/* <progress value={null} /> */}
                        {/* <button className='text-white my-10 border px-6 py-2 border-gray-900 hover:border-gray-500 rounded-full' onClick={() => { setEdituser(prevState => !prevState) }}>back</button> */}

                    </div>
                </div>
                :




                <div className="w-full h-full bg-black flex flex-col justify-center items-center my-[100px] md:my-[300px]">


                    <h1 className="text-3xl md:text-5xl font-bold my-5 text-white">Create Mechanic Profile</h1>

                    <form onSubmit={handleSubmit} className="flex flex-col  items-center space-y-3 text-white w-[90%] md:w-[30%]">


                        {/* <label htmlFor="file" className="cursor-pointer group">
                            <div className='border-10 group-hover:border-orange-300 rounded-full bg-white w-[15vw] my-5 h-[15vw] overflow-hidden flex items-center justify-center'>
                                <img className=" object-cover w-full h-full " src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${record[0].id}.jpg`} />
                            </div>
                        </label> */}


                        {/* <input id="file" className="p-2 hidden w-full rounded-full" type="file" onChange={handleUpload} /> */}


                        {/* {!username1 && <UsernameDefine names={names} setEdituser={setEdituser} />} */}
                        {/* <UsernameDefine names={names}/> */}
                        {/* {username1 && <p className="text-white">@{username1}</p>} */}


                        <fieldset className="border space-y-2 border-gray-600 p-5">

                            <legend>Workplace</legend>

                            <input
                                id="name"
                                className="p-2 hover:ring-2 ring-orange-500 outline-none w-full rounded-full bg-gray-400/10 border-0 placeholder-gray-600 px-3"
                                type="text"
                                placeholder="shopName"
                                name='shopName'
                                value={userInfo.shopName}
                                onChange={handleChange}
                                required
                            />


                            {/* 
                            <div className="flex my-5">
                                <input
                                    className="p-2 w-full rounded-full bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                    type="date"
                                    placeholder="DOB"
                                    name='dob'
                                    value={userInfo.dob}
                                    onChange={handleChange}
                                    required
                                />

                                <select
                                    name="gender"
                                    value={userInfo.gender}
                                    onChange={handleChange}
                                    className="border  bg-black border-gray-800 rounded p-2"
                                >
                                    <option value="">Select gender</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                    <option value="Other">Other</option>
                                </select>


                                <input
                                    className="p-2 w-full rounded-full bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                    type="text"
                                    placeholder="Blood Group"
                                    name="bloodGroup"
                                    value={userInfo.bloodGroup}
                                    onChange={handleChange}
                                />
                            </div> */}



                            <textarea
                                className="p-2 w-full rounded-md h-40 bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                type="text"
                                placeholder="street/nearby/area/sector etc..."
                                name="address"
                                value={userInfo.address}
                                onChange={handleChange}
                                required
                            />

                            <div className="flex">
                            <input
                                className="p-2 w-full border-b border-gray-800 bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                type="text"
                                placeholder="City"
                                name="city"
                                value={userInfo.city}
                                onChange={handleChange}
                                required
                            />
                            <input
                                className="p-2 w-full border-b border-gray-700 bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                type="text"
                                placeholder="State"
                                name="state"
                                value={userInfo.state}
                                onChange={handleChange}
                                required
                            />

                            <input
                                className="p-2 w-full border-b border-gray-700 bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                type="text"
                                placeholder="country"
                                name="country"
                                value={userInfo.country}
                                onChange={handleChange}
                                required
                            />
</div>
                            


                        </fieldset>


                        <fieldset className="border border-gray-600 p-5 rounded-xl space-y-3">

                            <legend>About Mechanic</legend>




                            <textarea
                                className="p-2 w-full rounded-md h-40 bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                type="text"
                                placeholder="Short Bio"
                                name="bio"
                                value={userInfo.bio}
                                onChange={handleChange}
                            />


                            <input
                                className="p-2 w-full rounded-full bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                type="text"
                                placeholder="experience in years"
                                name="experience"
                                value={userInfo.experience}
                                onChange={handleChange}
                            />

                        </fieldset>



                        <fieldset className="border border-gray-600 p-5 rounded-xl space-y-3">

                            <legend>Contacts</legend>


                            <input
                                className="p-2 w-full rounded-full bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={userInfo.email}
                                onChange={handleChange}
                            />


                            <input
                                className="p-2 w-full rounded-full bg-gray-400/10 border-1 placeholder-gray-600 px-3"
                                type="text"
                                placeholder="Mobile Number"
                                name="mobile"
                                value={userInfo.mobile}
                                onChange={handleChange}
                            />

                        </fieldset>


                        <button className="bg-orange-500 hover:bg-orange-600 rounded-full p-2 w-[30%] my-10" type="Update">Create Profile</button>



                    </form>
                    <button className='text-white my-10 border px-6 py-2 border-gray-900 hover:border-gray-500 rounded-full' onClick={() => { setShow(false) }}>back</button>

                </div>

            }
        </div>
    );
};















function UsernameDefine({ names }) {


    const [indicator, setIndicator] = useState({ available: false, upload: false });
    const [newUsername, setNewUsername] = useState({ username: '', user_id: '' });


    const { token } = useContext(MyContext);



    function handleChange(e) {

        const { value } = e.target;

        setNewUsername((prev) => ({ ...prev, username: value, user_id: token.user.id }));

        const usernameExists = names.some(member => member.username === value);

        if (usernameExists) { setIndicator({ available: true }); }

        else { setIndicator({ available: false }); }

    }



    async function uploadName() {
        const { data, error } = await supabase
            .from('userName')
            .insert(newUsername);

        const { data2, error2 } = await supabase
            .from('user')
            .update({ username: newUsername.username })
            .eq('user_id', token.user.id);


        if (error) {
            console.error('Error fetching usernames:', error);
        } else {
            console.log('data:', data);
            setIndicator({ upload: true })
        }
    }


    return (
        <div className="flex flex-col w-full justify-center items-center">

            <div className="flex w-full justify-center items-center">

                <input
                    className={`p-2 rounded-full { ${indicator.available ? 'ring-2 ring-red-500' : 'ring-2 ring-green-500'} outline-none bg-gray-400/10 border-1 placeholder-gray-600 px-3 mx-5`}
                    type="text"
                    placeholder="username"
                    name="type username"
                    value={newUsername.username}
                    onChange={handleChange}
                    required
                />

                {indicator.available ? <p className="text-red-500">Username not available</p> : <button className="bg-green-500 py-2 px-5 rounded-full text-black" onClick={uploadName}>Create Username</button>}


            </div>

            <p className="py-5" >You can create and set username only once.</p>

        </div>
    );
}




export default MechanicProfileCreate;
