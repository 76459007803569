import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../../client'
import AddNewProfile from './AddNewProfile'
import EditProfile from './EditProfile'
import InsertBike from '../posts/InsertBike'
import MyContext from '../Components/MyContext'
import InsertRidePost from '../Ride/InsertRidePost'
import MechanicProfileCreate from '../mechanic/mechanicProfileCreate'
import Delete from '../posts/Delete'






const UserProfile = ({ token }) => {


    let navigate = useNavigate()

    const [ride, setRide] = useState([]);
    const [record, setRecord] = useState([]);
    const [bikes, setBikes] = useState([]);
    const [adduser, setAdduser] = useState(false);
    const [friend, setFriend] = useState([]);

    const [edituser, setEdituser] = useState(false);

    const { username1, userData, setUsername1, Refresh, refresh, NavigateButton } = useContext(MyContext)



    useEffect(() => {
        async function fetchUserData() {
            const { data, error } = await supabase
                .from('user')
                .select('*')
                .filter('user_id', 'eq', token.user.id); // Filter by ID

            if (data && data.length > 0) {
                setRecord(data);
                setFriend(data[0].friends);
                // setUsername1(data[0].username);
                // localStorage.setItem('username1', data[0].username); // Store in localStorage
            } else {
                setAdduser(true);
            }
        }

        fetchUserData();
    }, [refresh]);





    //fetch bikes data
    useEffect(() => {

        async function fetchBike() {

            const { data, error } = await supabase
                .from('bike')
                .select('*')
                .filter('user_id', 'eq', token.user.id); // Filter by ID
            setBikes(data);
        }
        fetchBike();
    }, [refresh]);






    //fetch joined Rides 
    useEffect(() => {

        async function fetchRide() {

            const { data, error } = await supabase
                .from('ride')
                .select('*')
            // .filter('user_id', 'eq', token && token.user.id); // Filter by ID

            // .filter('join', 'eq', record.length > 0 && record[0].username); // Filter by ID
            setRide(data);
            console.log('rideData', data)
        }

        fetchRide();

    }, [refresh]);






    return (
        <div className='w-full grid grid-cols-1 h-full overflow-scroll justify-start items-center py-0  bg-black fixed'>

            <div className='py-20 '>

                {record && record.length > 0 &&



                    <div className='flex border-2 border-slate-800 rounded-xl mx-8 py-8 flex-col justify-center items-center'>

                        <div className='rounded-full flex w-[30vw] h-[30vw] overflow-hidden mb-5'>
                            <img className='object-cover' src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${record[0].id}.jpg?c=1`} />
                        </div>

                        <div className='flex overflow-hidden flex-col justify-center items-center text-center space-y-3 mb-10'>
                            <p className='text-[6vw] font-bold text-white text-wrap break-words'>{record[0].name}</p>
                            <p className='text-[4vw] font-base text-white'>@{username1}</p>

                            <p className='text-[4vw] font-base text-white'>Blood-Group {record[0].bloodGroup}</p>
                            <p className='text-[4vw] font-semibold text-white'>{record[0].city}, {record[0].state}, {record[0].country}</p>
                        </div>

                        <p className='text-[4vw] font-base text-white'>Riding</p>
                        <p className='text-[4vw] font-bold text-white'>{record[0].bike}</p>
                        <p className='text-[4vw] font-base text-white'>Since 1995</p>


                        <p className='text-[4vw] font-base text-white mt-5'>Club</p>
                        <p className='text-[4vw] font-semibold  text-white'>{record[0].club}</p>
                        <span className='font-thin text-gray-300 mr-1'>Member</span>


                        {/* {bikes.map((bike, index) => ( <p key={index} className='text-[3vw] font-bold text-white'> Riding - {bike.maker} </p> ))} */}


                        <div className='w-full h-full p-10 my-5 border-y border-gray-800 space-y-5 '>
                            <p className='text-[4vw] font-thin text-white text-center'>About Myself</p>
                            <p className='text-[4vw]  text-white text-center'>{record[0].bio} </p>
                        </div>

                        <button className='text-white mb-10' onClick={() => { setEdituser(prevState => !prevState) }}>Edit Profile</button>

                    </div>}


                <div className='border-0 border-slate-500  h-full'>
                   
                   <div className='flex h-full px-10 py-10 space-x-5 items-center justify-center'>
                        <p className='text-white text-2xl'>My Rides</p>
                        <InsertRidePost token={token.user.id} />
                    </div>
                    
                    <div className='flex overflow-x-scroll'>

                        {ride && ride.length > 0 && (
                            ride.map((rides, index) => (
                                // <p className='text-white' key={index}>{rides}</p>
                                // <NavigateButton path={'/ride/' + rides.id} name={rides.header} />
                                rides.joinBy.includes(username1) &&

                                <div className='h-[200px] cursor-pointer' key={index} onClick={() => navigate('/ride/' + rides.id)} >


                                    <div className='h-[150px] w-[250px] p-5 bg-gray-500/20 mx-2 rounded-xl'>
                                        <p className='text-white py-1' >{rides.header}</p>
                                        <p className='text-white py-1' >{rides.date}</p>
                                        <p className='text-white py-1' >Join By {rides.joinBy ? rides.joinBy.length : 0} People</p>

                                    </div>

                                </div>

                            ))
                        )}



                    </div>


                </div>


                <div className='border border-slate-900 bg-red-000 overflow-scroll h-full px-10 py-5 space-y-5'>


                    <MechanicProfileCreate />


                    {/* my friends */}
                    <div className='bg-gray-500/20 p-3 rounded-xl flex flex-col items-start space-y-3 h-max'>
                        <p className='text-white text-2xl mb-2'>Friends</p>

                        {userData && userData[0].friends && userData[0].friends.map((friend, index) => (

                            <button key={index} className='text-white hover:bg-gray-800 rounded-xl duration-700 px-5 border border-gray-800 py-1' onClick={() => navigate(`/${friend}`)}>@{friend}</button>

                        )
                        )}

                    </div>


                    {/* my Garage */}
                    <div className='bg-gray-500/20 p-3 rounded-xl flex flex-col items-start space-y-3'>

                        <div className='grid grid-cols-2 gap-5 justify-center items-center w-full border-b border-gray-700 pb-3'>
                            <p className='text-white text-2xl mb-2'>My Garage</p>
                            <InsertBike token={token.user.id} />
                        </div>

                        {bikes.length > 0 && (bikes.map((bike, index) => (<BikeView key={index} bikeData={bike} />)))}

                    </div>



                </div>

            </div>













            {adduser && <AddNewProfile userId={token.user.id} />}

            {edituser && <EditProfile userId={token.user.id} record={record} setEdituser={setEdituser} />}


        </div>
    )
}







function BikeView({ bikeData }) {

    const { maker, model, ownYear, year, number, id } = bikeData;

    return (

        <div className='bg-gray-500/10 p-3 w-full h-auto flex justify-between'>

            <div>
                <p className='text-white text-lg font-semibold mb-2' >{maker} {model}</p>
                <p className='text-gray-400 text-sm' >Make Year {year}</p>
                <p className='text-gray-400 text-sm' >Own Year {ownYear}</p>
                <p className='text-gray-400 text-sm' >Number {number}</p>
            </div>
            <div className='flex justify-start items-start'>
                <Delete id={id} table='bike' item={model} />
            </div>
        </div>
    );
}





export default UserProfile




