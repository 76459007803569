import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import MyContext from './Components/MyContext';
import { supabase } from '../client';




const Members = () => {




  const [record, setRecord] = useState([]);
  const [user, setUser] = useState();
  const [filterby, setFilterby] = useState({ all: true, city: false, state: false, club: false, friends: false, search: false });
  const { username1, Refresh, token, refresh, userData } = useContext(MyContext);
  const [search, setSearch] = useState('');
    
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    getCountries();
  }, []);

  async function getCountries() {
    const { data } = await supabase.from("user").select();
    setRecord(data);
  }



  useEffect(() => {
    getUser();
  }, [token]);

  async function getUser() {
    const { data, error } = await supabase
      .from('user')
      .select('*')
      .filter('user_id', 'eq', token && token.user.id); // Filter by ID
    console.log('user', data);

    setUser(data);
  }


  function handleSearch(e) {
  
    setSearch(e.target.value);
    setFilterby({search:true});
  
  }




  const Filtericon = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
  <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5"/>
</svg>



  return (
    <div className="fixed w-full h-screen bg-black overflow-scroll grid grid-flow-row grid-col-1">

      <div className='animate-fade-down ease-linear bg-slate-500/10 px-10 pt-5 space-y-5 flex flex-col justify-start items-start'>

        <div className='flex justify-between w-full'>
        <p className='text-orange-400 text-2xl'>Explore People</p>
        <button onClick={()=>setFilter(prevState => !prevState)} className='text-orange-400 text-2xl'>{Filtericon}</button>
        </div>


        {filter && 
        
        <div className='animate-fade-down flex space-x-7'>
            <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ city: true })}>My City </button>
            <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ state: true })}>My State</button>
            <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ club: true })}>My Club</button>
            {/* <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ friends: true })}>My Friends</button> */}
            <button className='ml-1 text-md text-white border-b border-gray-900 hover:border-orange-400 hover:border-b duration-500' onClick={() => setFilterby({ all: true })}>Clear Filter</button>
        </div>
}

        <input
          className="p-2 w-[100%] rounded-full border outline-none"
          type="text"
          placeholder="Search..."
          name='search'
          value={search}
          onChange={handleSearch}
        />



      </div>


      {user && 
      <div className="grid grid-cols-1 gap-5 px-3 place-content-start py-20">

        {filterby.all && record.map((member, index) => <MemberCardMobile key={index} data={member} />)}
        {filterby.city && record.filter(member => member.city === user[0].city).map((member, index) => (<MemberCardMobile key={index} data={member} />))}
        {filterby.state && record.filter(member => member.state === user[0].state).map((member, index) => (<MemberCardMobile key={index} data={member} />))}
        {filterby.club && record.filter(member => member.club === user[0].club).map((member, index) => (<MemberCardMobile key={index} data={member} />))}
        {/* {filterby.friends && record.filter(member => member.friends.toLowerCase().includes(user[0].username.toLowerCase())).map((member, index) => (<MemberCardMobile key={index} data={member} />))} */}
        {filterby.search && record.filter(member => member.name.toLowerCase().includes(search.toLowerCase())).map((member, index) => ( <MemberCardMobile key={index} data={member} /> ))}

      </div>
    }

    </div>
  );
};














const MemberCardMobile = (props) => {

  const { name, club, state, city, id, bike,username } = props.data;
  
  const navigate = useNavigate();

  return (

    <div onClick={()=>navigate(`/${username}`)} className="cursor-pointer rounded-2xl flex items-center overflow-hidden h-[115px] space-x-3 p-2 bg-gray-500/10 hover:border-gray-800 duration-300" >

      <div className="bg-red-600 border h-[100px] w-[100px] flex rounded-full overflow-hidden" >
        <img className='object-cover w-full flex' src={`https://yjqfvaqpwdiblozmnruy.supabase.co/storage/v1/object/public/tsuData/profileImage/avatar${id}.jpg?c=1`} />
      </div>

      <div className='text-left space-y-2 bg-gray-000'>
        <p className="text-white text-md lg:text-lg m-auto" >{name}</p>
        <p className="text-white text-xs lg:text-sm">{city}, {state}</p>
        <p className="text-white text-xs lg:text-sm">{bike}</p>
        <p className="text-white text-xs lg:text-sm">{club}</p>
      </div>

    </div>
  );
};






export default Members;
