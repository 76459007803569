import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Pages/Components/Navbar';
import NavbarMobile from './Pages/Components/NavbarMobile';
import MyContext from './Pages/Components/MyContext';

import Login from './Pages/supaBase/Login';
import LoginMobile from './Pages/supaBase/LoginMobile';

import useSafeAreaInsets from './Pages/Components/useSafeAreaInsets'; // Assuming the hook is saved as useSafeAreaInsets.js








const Layout = ({children}) => {
  

  const width = window.screen.width;

  const insets = useSafeAreaInsets();

  const style = {
    paddingTop: insets.top,
    paddingRight: insets.right,
    paddingBottom: insets.bottom,
    paddingLeft: insets.left,
  };


  const { userData, setUserData, username1, Refresh, token,setToken, refresh } = useContext(MyContext);


  return (
    <div style={style} className='bg-black w-full h-full'>
      {width >= 450 ? <Navbar/> : <NavbarMobile/>}
      
      {token ? 
      <div className="pt-[70px] w-full h-full bg-black">

      {children}
      
      </div>
    : (width >= 450 ? <Login setToken={setToken}/> : <LoginMobile setToken={setToken}/> )}
    </div>
  );
};

export default Layout;

