import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyContext from '../Components/MyContext';
import { supabase } from '../../client';
import AddasFriend from '../functions/AddasFriend.jsx';
import { formatDistanceToNow } from 'date-fns';
import InsertLike from './InsertLike';





const PostHome = () => {



    const [record, setRecord] = useState([]);
    const [friendsList, setFriendsList] = useState([]);
    const [added, setAdded] = useState(false);
    const { userData, setUserData, username1, Refresh, token, refresh } = useContext(MyContext);



    useEffect(() => {
        getData();
    }, [refresh]);

    // Fetch posts from DB
    async function getData() {
        const { data } = await supabase.from('posts').select();
        setRecord(data);
    }

    // It Loads All current UserData
    useEffect(() => {
        if (localStorage.getItem('userData')) {
            const wowo2 = JSON.parse(localStorage.getItem('userData'));
            setFriendsList(wowo2[0].friends);
            friendsList && console.log('ok', friendsList);
        }
    }, []);

    return (
        <div className='overflow-scroll h-[60%] py-20 px-3 md:px-10'>
            <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-3 grow'>
                {record.slice().reverse().map((member, index) => (
                    <PostView key={index} data={member} friendCheck={friendsList} />
                ))}
            </div>
        </div>
    );
};










const PostView = (props) => {



    const { post, uid, post_id, created, username } = props.data;
    const navigate = useNavigate();
    const [timeAgo, setTimeAgo] = useState('');

    const { userData, setUserData, username1, Refresh, token, refresh } = useContext(MyContext);

    const width = window.innerWidth; // Use window.innerWidth to get the current width




    useEffect(() => {
        const updateTime = () => {
            setTimeAgo(formatDistanceToNow(new Date(created), { addSuffix: true }));
        };

        // Update the time every minute
        const intervalId = setInterval(updateTime, 60000);

        // Set the initial time
        updateTime();

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [created]);






    // Conditional rendering based on screen width
    const renderDesktop = () => (
        <div className="animate-fade-up rounded-2xl flex justify-end items-end p-5 animate-ease-linear border border-gray-800 hover:border-orange-500 duration-200">
            <div className='flex flex-col justify-center items-center space-y-3 bg-red-000 w-full h-full text-center'>
                
                
                <div className='h-[85%] bg-slate-000 w-full flex-grow flex-wrap'>
                    <p className="text-white text-xs lg:text-3xl break-all">{post}</p>
                </div>
                
                
                
                <div className='h-[15%] flex flex-col justify-evenly items-center space-x-3 bg-slate-000 py-3 w-full'>

                    <p className="text-gray-500 text-md lg:text-sm">
                        Posted by
                        <span className='text-orange-400 hover:text-orange-200 px-2'>
                            <button onClick={() => navigate(`/${username}`)}>{username}</button>
                        </span> on {timeAgo}
                    </p>

                </div>
                
                <InsertLike post_id={post_id} />

                {username !== username1 && (props.friendCheck && props.friendCheck.includes(username) ? (
                    <p className='text-gray-200'>Friends</p>
                ) : (
                    <AddasFriend username={username} />
                ))}




            </div>
        </div>
    );




    const renderMobile = () => (
        <div className="animate-fade-up rounded-2xl flex justify-end items-end p-5 animate-ease-linear border border-gray-800 hover:border-orange-500 duration-1000">

            <div className='flex flex-col justify-center items-center space-y-3 bg-red-000 w-full h-full text-center'>

                <div className='h-[95%] bg-slate-000 w-full flex-grow'>
                    <p className="text-white text-lg break-all">{post}</p>
                    <p className="text-white text-xs lg:text-sm">{uid}</p>
                </div>

                <div className='h-[15%] flex justify-evenly items-center space-x-3 bg-slate-000 py-3 w-full'>
                    <p className="text-gray-500 text-sm">
                        Posted by
                        <span className='text-orange-400 hover:text-orange-200 px-2'>
                            <button onClick={() => navigate(`/${username}`)}>{username}</button>
                        </span> on {timeAgo}
                    </p>
                </div>

                {username !== username1 && (props.friendCheck && props.friendCheck.includes(username) ? (
                    <p className='text-gray-200'>Friends</p>
                ) : (
                    <AddasFriend username={username} />
                ))}
            </div>
        </div>
    );




    return width >= 450 ? renderDesktop() : renderMobile();
};

export default PostHome;
