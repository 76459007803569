import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../../client';
import MyContext from '../Components/MyContext';






const Notifications = () => {


  const { token, username1 } = useContext(MyContext);

  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);





  //notification fetch based on username
  useEffect(() => {

    const fetchUserNotifications = async () => {

      try {
        const { data, error } = await supabase
          .from('notification')
          .select('*')
          .eq('for', username1)
        if (error) {
          console.error("Error fetching Notofocations:", error.message);
          return null;
        }

        setNotifications(data);
        console.log('fetched data', data)

      } catch (error) {
        console.error("Error in Notification ID:", error.message);
        return null;
      }
    };


    fetchUserNotifications();


  }, [username1, handleNotificationClick]);




//here when click on notification, it become read false
  async function  handleNotificationClick(id){

    const { data, error } = await supabase
    .from('notification')
    .update({ read: false })
    .eq('id', id)
    .select()
            
    console.log(data.read);
  }




  function View({data}) {

    const { text, by, created_at, id, read } = data;
  
    return (
  
      <div onClick={()=> (handleNotificationClick(id), setOpen(false)) } className={`${read ? 'text-green-500' : 'text-white'}  bg-black p-5 cursor-pointer`}>
        <p className='text-sm'>{by} {text}</p>
        {/* <small>{new Date(created_at).toLocaleString()}</small> */}
      </div>
  
    )
  
  }
  

  const bellIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
</svg>




  return (
    <div className='relative flex items-center'>


      <button onClick={() => setOpen(prevOpen => !prevOpen)} className='text-white text-sm flex space-x-2'> <p> Notification</p> {bellIcon}</button>


      {open && 
      <div className='absolute w-[300px] top-[63px] rounded-xl overflow-hidden border-2 border-gray-600'> 
      {open &&

        (notifications.length > 0 && notifications.map((notification, index) => (<View key={index} data={notification} />)))

      }
      </div>
      }


    </div>
  );
};
















export default Notifications;
