import React, { useState, useContext } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";







const Delete = ({ table, id, item }) => {

    const [prompt, setPrompt] = useState(false);
    const { Refresh, username1, userData, token, refresh } = useContext(MyContext)
    const [success, setSuccess] = useState(false);






    const handleDelete = async () => {

        try {

            const { error } = await supabase
                .from(table)
                .delete()
                .eq('id', id)

            if (error) {
                console.error("Failed to delete bike data:", error.message);
                return;
            }

            setSuccess(true);
            Refresh();
            
            console.log('delete done')

            setTimeout(() => {
                setPrompt(false);
            }, 5000);

        } catch (error) {
            console.error("Error deleting data:", error.message);
        }
    };



    const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
    </svg>;


    return (

        <>

            <button className={`  text-white my-10 hover:text-orange-600`} onClick={() => setPrompt(true)}>{removeIcon}</button>

            {prompt && <div className="w-screen space-y-5 h-screen absolute top-0 left-0 backdrop-blur-sm bg-black/60 flex flex-col justify-center items-center">
                
                <p className="text-white text-3xl">
                    {success ? `Removed Sucessfully` : `Are you realy want to remove ${item}`}
                </p>

                <div className="space-x-5">
                    <button className='text-white hover:bg-red-700 rounded-xl bg-red-500 p-3' onClick={handleDelete}>{success ? 'Deleted' : 'Delete'} </button>
                
                    {!success && 
                    <button className='text-white hover:bg-red-700 rounded-xl bg-green-500 p-3' onClick={() => setPrompt(false)}>Cancel</button>
                    }
                
                </div>
            </div>
            }        
        </>
    );
};





export default Delete;



