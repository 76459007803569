import React, { useState, useContext, useEffect } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";
import { format } from 'date-fns';









const MechanicInsertPost = ({username}) => {
   
    const [uploading, setUploading] = useState(false);
    const { token, Refresh, refresh } = useContext(MyContext);


    const username1 = localStorage.getItem('username1')

    const getCurrentDateTime = () => {
        const now = new Date();
        return format(now, 'yyyy-MM-dd HH:mm:ss');
    };

    const [post, setPost] = useState({
        user_id: token && token.user.id, // Check if token.user exists
        post: '',
        postBy: username1 && username1,
        dateTime: new Date().toISOString(),
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPost({ ...post, [name]: value });
    };




    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Fetch the existing posts for the user
            console.log('chakko',username);
            const { data: existingPosts, error: fetchError } = await supabase
                .from('mechanic')
                .select('posts')
                .eq('username', username)
                .single();

            if (fetchError) {
                console.error("Failed to fetch existing posts:", fetchError.message);
                return;
            }

            const updatedPosts = existingPosts.posts ? [...existingPosts.posts, post] : [post];

            // Update the posts column with the new array
            const { data, error } = await supabase
                .from('mechanic')
                .update({ posts: updatedPosts })
                .eq('username', username);

            if (error) {
                console.error("Failed to update posts:", error.message);
                return;
            }

            console.log("Data updated successfully:", data);
            // Clear the form after successful submission
            setPost({
                user_id: token.user.id,
                post: '',
                postBy: username1,
                dateTime:'',
            });
            Refresh();
            setUploading(true);
        } catch (error) {
            console.error("Error inserting data:", error.message);
        }
    };


    const width = window.screen.width;



    return (
        <div className="w-full h-[200px] md:h-full">
            
            
            {uploading && (
                <div className="absolute top-0 left-0 w-screen h-screen bg-black/80 flex justify-center items-center z-40">
                    <div className="animate-fade-up space-y-5 w-[30%] h-[30%] bg-black rounded-3xl border border-gray-500 flex flex-col justify-center items-center">
                        <p className="text-white text-3xl">Posted Successfully</p>
                        <button className="text-white" onClick={() => setUploading(false)}>Close</button>
                    </div>
                </div>
            )}



            <div className="w-full h-full bg-gray-000 flex flex-col md:flex-row space-x-4 justify-center items-center">
                <h1 className="text-sm font-bold text-white">Post on Profile</h1>
                <form onSubmit={handleSubmit} className="flex items-center w-[80%] space-x-1">
                    <input
                        className="p-2 w-[100%] rounded-full"
                        type="text"
                        placeholder="Type..."
                        name='post'
                        value={post.post}
                        onChange={handleChange}
                        required
                    />
                    <button className="bg-orange-500 hover:bg-orange-600 rounded-full p-2 w-[30%]" type="submit">
                    {uploading ? 'Posted' : 'Post'}
                    </button>
                </form>
            </div>


        </div>
    );



};

export default MechanicInsertPost;
