import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './Pages/Home';
import HomeMobile from './Pages/HomeMobile';

import AboutUs from './Pages/AboutUs';

import Members from './Pages/Members';
import MembersMobile from './Pages/MembersMobile';


import AdminPanel from './Pages/AdminPanel';
import MembersView from './Pages/MembersView';

import SignUp from './Pages/supaBase/SignUp';
import SignUpMobile from './Pages/supaBase/SignUpMobile';

import Login from './Pages/supaBase/Login'
import LoginMobile from './Pages/supaBase/LoginMobile'

import UserProfile from './Pages/supaBase/UserProfile';
import UserProfileMobile from './Pages/supaBase/UserProfileMobile';

import UserHome from './Pages/supaBase/UserHome';
import UserHomeMobile from './Pages/supaBase/UserHomeMobile';



import Navbar from './Pages/Components/Navbar';
import Layout from './Layout';
import DataMain from './Database/DataMain';
import MyContext from './Pages/Components/MyContext';
import { supabase } from './client';
import Mechanics from './Pages/mechanic/Mechanics';
import MechanicProfile from './Pages/mechanic/mechanicProfile';
import MarketHome from './Pages/market/MarketHome';

import Map from './Pages/Components/Map';

import ViewRideSingle from './Pages/Ride/ViewRideSingle';
import ViewRideSingleMobile from './Pages/Ride/ViewRideSingleMobile';
import MechanicsMobile from './Pages/mechanic/MechanicsMobile';
import MechanicProfileMobile from './Pages/mechanic/mechanicProfileMobile';








function App() {



  const width = window.screen.width;

  const { username1, setUsername1, userData, setUserData, token, setToken } = useContext(MyContext);


  if (token) {
    sessionStorage.setItem('token', JSON.stringify(token))
    console.log('token set');
  }

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      let data = JSON.parse(sessionStorage.getItem('token'))
      setToken(data)
      console.log('token set to state');

    }

  }, [])






  useEffect(() => {

    async function fetchUserData() {

      const { data, error } = await supabase
        .from('user')
        .select('*')
        .filter('user_id', 'eq', token && token.user.id); // Filter by ID


      if (data && data.length > 0) {
        setUsername1(data[0].username);
        localStorage.setItem('userData', JSON.stringify(data)); // Store in localStorage
        localStorage.setItem('username1', data[0].username); // Store in localStorage
        const wowo = JSON.parse(localStorage.getItem('userData'))
        setUserData(wowo);

      }
      else console.log('no data');

    }

    fetchUserData();

  }, [token]);





  return (
    <div className='w-full overflow-hidden bg-black'>
      <Layout>
        <Routes>


          {/* <Route path={'/Login'} element={ <Login setToken={setToken}/>} /> */}

          {token
            ? <Route path="/" element={width >= 450 ? <UserHome username1={username1} /> : <UserHomeMobile username1={username1} />} />
            : <Route path={'/'} element={width >= 450 ? <Home setToken={token} /> : <HomeMobile setToken={token} />} />
          }

          {token
            ? <Route path={'/profile'} element={width >= 450 ? <UserProfile token={token} />
              : <UserProfileMobile token={token} />} /> : null
          }

          <Route path="/signup" element={width >= 450 ? <SignUp /> : <SignUpMobile />} />
          <Route path="/login" element={width >= 450 ? <Login setToken={setToken} /> : <LoginMobile setToken={setToken} />} />


          {/* <Route path="/admin" element={<AdminPanel/>} /> */}
          {/* <Route path="/about" element={<AboutUs/>} /> */}

          <Route path="/users" element={width >= 450 ? <Members /> : <MembersMobile />} />

          <Route path="/market" element={<MarketHome />} />
          <Route path="/:userId" element={<MembersView />} />
          
          {/* <Route path="/mechanics" element={<Mechanics />} /> */}
          <Route path="/mechanics" element={width >= 450 ? <Mechanics /> : <MechanicsMobile />} />
          <Route path="/mechanics/:userId" element={width >= 450 ? <MechanicProfile /> : <MechanicProfileMobile />} />
          
          
          {/* <Route path="/mechanics/:userId" element={<MechanicProfile />} /> */}


          <Route path="/explore" element={width >= 450 ? <UserHome username1={username1} /> : <UserHomeMobile username1={username1} />} />

          <Route path="/ride/:rideId" element={width >= 450 ? <ViewRideSingle token={token} /> : <ViewRideSingleMobile token={token} />} />

          <Route path="/map" element={<Map />} />


        </Routes>
      </Layout>
    </div>
  );
}

export default App;
