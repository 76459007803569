import React, { useState, useContext, useEffect } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";
const { v1: uuidv1 } = require('uuid');

const MarketInsertPost = () => {
   
   
    const [uploading, setUploading] = useState(false);
    const { token, Refresh } = useContext(MyContext);
    const [username1, setUsername1] = useState('');
    const [create, setCreate] = useState(false);
    const [image, setImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        const storedUsername1 = localStorage.getItem('username1');
        if (storedUsername1) {
            setUsername1(storedUsername1);
        }
    }, []);


    const [post, setPost] = useState({
        user_id: token.user ? token.user.id : '',
        post: '',
        city: '',
        state: '',
        price: '',
        type: '',
        image: '',
        username: localStorage.getItem('username1') || ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPost({ ...post, [name]: value });
    };

    const handleUpload = async (e) => {
        const avatarFile = e.target.files[0];
        const imageName = `twoStrokeUnitedMarket${uuidv1()}.jpg`;
        setPost(prevPost => ({ ...prevPost, image: imageName }));
        setImage(avatarFile);
        setPreviewImage(URL.createObjectURL(avatarFile));

    };

    const handleUpload2 = async () => {
        if (!image || !post.image) return;

        try {
            const { data, error } = await supabase
                .storage
                .from('marketImages')
                .upload(post.image, image, {
                    upsert: true
                });

            if (error) {
                console.error("Failed to upload:", error.message);
                setUploading(false);
                return;
            }
            console.log("Image uploaded successfully:", data);
            return data;
        } catch (error) {
            console.error("Error uploading image:", error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await handleUpload2();

        try {
            const { data, error } = await supabase
                .from('market')
                .insert(post)
                .select();
            if (error) {
                console.error("Failed to insert data:", error.message);
                return;
            }

            console.log("Data inserted successfully:", data);
            setPost({
                user_id: token.user.id,
                post: '',
                city: '',
                state: '',
                price: '',
                type: '',
                image: '',
                username: username1
            });
            Refresh();
            setUploading(true);
        } catch (error) {
            console.error("Error inserting data:", error.message);
        }
    };







    const InsertDesktop = () => (
        <div className="w-full h-full">
            {uploading && (
                <div className="absolute top-0 left-0 w-screen h-screen bg-black/80 flex justify-center items-center z-40">
                    <div className="animate-fade-up space-y-5 w-[30%] h-[30%] bg-black rounded-3xl border border-gray-500 flex flex-col justify-center items-center">
                        <p className="text-white text-3xl">Posted Successfully</p>
                        <button className="text-white" onClick={() => setUploading(false)}>Close</button>
                    </div>
                </div>
            )}

            <div className="w-full h-full bg-gray-000 flex space-x-10 justify-center items-center">
                {create && (
                    <div className="absolute w-full h-full bg-black/50 backdrop-blur-xl top-0 left-0 flex flex-col space-y-2 justify-center items-center">
                        <p className="text-white text-[3vw]">Market Post</p>
                        <p className="text-white">@{username1}</p>

                        <div className="w-[30vw] h-[17vw] bg-red-200 rounded-xl overflow-hidden">
                        
                            <label htmlFor="file" className="cursor-pointer group">
                                
                                    {post.image && ( <img className="object-cover w-full h-full" src={previewImage} /> )}
                            
                            </label>
                        
                            <input id="file" className="p-2 hidden w-full rounded-full" type="file" onChange={handleUpload} />
                        
                        </div>

                        <form onSubmit={handleSubmit} className="flex flex-col items-center w-[30%] space-y-2">
                            <textarea
                                className="p-2 w-[100%] h-[6vw] rounded-xl"
                                type="text"
                                placeholder="Type..."
                                name='post'
                                value={post.post}
                                onChange={handleChange}
                                required
                            />

                            <div className="flex space-x-2">
                                <input
                                    className="p-2 w-[100%] rounded-xl"
                                    type="text"
                                    placeholder="City"
                                    name='city'
                                    value={post.city}
                                    onChange={handleChange}
                                    required
                                />

                                <input
                                    className="p-2 w-[100%] rounded-xl"
                                    type="text"
                                    placeholder="State"
                                    name='state'
                                    value={post.state}
                                    onChange={handleChange}
                                    required
                                />

                                <input
                                    className="p-2 w-[100%] rounded-xl"
                                    type="text"
                                    placeholder="Price"
                                    name='price'
                                    value={post.price}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="flex justify-between w-full">
                                <select
                                    name="type"
                                    value={post.type}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded p-2 rounded-xl"
                                    required
                                >
                                    <option value="">Select Type</option>
                                    <option value="sale">Sale</option>
                                    <option value="purchase">Purchase</option>
                                </select>

                                <button className="bg-orange-500 hover:bg-orange-600 rounded-full p-2 w-[50%]" type="submit">
                                    Create Post
                                    {/* <img alt='about-akai-india' className='object-cover w-[30px] h-auto' src={process.env.PUBLIC_URL + "./icons8-loading.gif"}></img> */}
                                    {/* <lord-icon trigger="hover" src="/my-icon.json"></lord-icon> */}
                                </button>
                            </div>
                        </form>
                    </div>
                )}

                <button className="bg-orange-500 hover:bg-orange-600 rounded-full p-2 w-[30%]" onClick={() => setCreate(true)}>
                    Create Post Sale/Purchase
                </button>
            </div>
        </div>
    );

    const InsertMobile = () => (
        <div className="w-full h-full">
            {uploading && (
                <div className="absolute top-0 left-0 w-screen h-screen bg-black/80 flex justify-center items-center z-40">
                    <div className="animate-fade-up space-y-5 w-[30%] h-[30%] bg-black rounded-3xl border border-gray-500 flex flex-col justify-center items-center">
                        <p className="text-white text-3xl">Posted Successfully</p>
                        <button className="text-white" onClick={() => setUploading(false)}>Close</button>
                    </div>
                </div>
            )}

            <div className="w-full h-full bg-gray-000 flex space-x-5 justify-center items-center">
                <form onSubmit={handleSubmit} className="flex items-center w-full space-x-2">
                    <input
                        className="p-2 w-[100%] rounded-full"
                        type="text"
                        placeholder="Share with Union"
                        name='post'
                        value={post.post}
                        onChange={handleChange}
                        required
                    />

                    <button className="bg-orange-500 hover:bg-orange-600 rounded-full p-2 w-[35%]" type="submit">
                        Share

                    </button>
                </form>
            </div>
        </div>
    );

    return window.innerWidth >= 450 ? InsertDesktop() : InsertMobile();
};

export default MarketInsertPost;
