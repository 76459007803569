import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import MyContext from '../Components/MyContext';
import { supabase } from '../../client';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import UpdateArray from '../posts/UpdateArray';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';















const ViewRide = ({ username }) => {


    const [record, setRecord] = useState([]);
    const { username1, Refresh, token, refresh } = useContext(MyContext)


    useEffect(() => {

        getData();

    }, [refresh]);



    async function getData() {
        const { data } = await supabase.from("ride").select();
        setRecord(data);
    }


    return (

        <div className='overflow-scroll h-[60%] py-20 px-3 md:px-10 '>
            <div className=" w-full grid grid-cols-1 md:grid-cols-3 gap-5 ">


                {record.slice().reverse().map((member, index) => (
                    <Link key={index} to={`/ride/${member.id}`}>
                    <RidePostView key={index} data={member} />
                    </Link>
                ))}

            </div>
        </div>
    );
};










const RidePostView = (props) => {

    const { id, created, header, from, to, description, perHead, time, date, join, completed, cancel, user_id, host } = props.data;

    const navigate = useNavigate();
    const { username1, Refresh, token, refresh } = useContext(MyContext)
    const [enlarge, setEnlarge] = useState(false);

    const [added, setAdded] = useState(false);
    const [friendsList, setFriendsList] = useState([]);

    const width = window.innerWidth; // Use window.innerWidth to get the current width


    useEffect(() => {

        const fetchFriendsList = async () => {
            try {
                const { data, error } = await supabase
                    .from('ride')
                    .select('join')
                    .eq('id', id)
                    .single();

                if (error) {
                    console.error("Failed to fetch friends list:", error.message);
                    return;
                }

                setFriendsList(data.join || []);
            } catch (error) {
                console.error("Error fetching friends list:", error.message);
            }
        };

        fetchFriendsList();
    }, []);








    const handleSubmit = async () => {
        try {
            // Check if the username is already in the friends list
            // if (friendsList.includes(username1)) {
            //     console.log("Username already exists in the friends list.");
            //     setAdded(true);
            //     return;
            // }
            //  else{   
            const updatedFriendsList = [...friendsList, username1];

            const { data, error } = await supabase
                .from('ride')
                .update({ join: updatedFriendsList })
                .eq('id', id)
                .select();

            // UpdateArray("user", "ride", "user_id", token.user.id, id);

            if (error) {
                console.error("Failed to join:", error.message);
                return;
            }

            console.log("Join successfully:", data);
            // }

        } catch (error) {
            console.error("Error Join:", error.message);
        }
    };




    const ViewDesktop = () => (
            
            <>
            <div className="animate-fade-up rounded-2xl flex justify-end items-end p-5 animate-ease-linear border border-gray-800 hover:border-orange-500 duration-1000" >

                <div className='flex flex-col justify-center items-center space-y-3 bg-red-000 w-full h-full text-center'>
                    <button className='text-white' onClick={() => setEnlarge(true)}>View Full Deatils</button>

                    <div className='h-[85%] bg-slate-000 w-full flex-grow space-y-3'>
                        <p className="text-white text-xs lg:text-3xl">{header} </p>
                        <p className="text-white text-xs lg:text-lg">From {from} To {to}</p>
                        <p className="text-white text-xs lg:text-sm">On Date {date} | Departure at: {time}</p>
                    </div>

                    <div className='h-[15%] flex justify-evenly items-center space-x-3 bg-slate-000 py-3 w-full'>
                        <p className="text-gray-500 text-md lg:text-sm " >Ride Organised by

                            <span className='text-orange-400 hover:text-orange-200 px-2'>

                                <button onClick={() => navigate(`/${host}`)}>{host}</button>

                            </span> on {created.slice(0, 10)} </p>
                    </div>

                </div>

            </div>


            {enlarge &&

                <div className="absolute w-screen h-[80%] top-0 left-0 z-10 rounded-2xl p-5 flex flex-wrap justify-center overflow-hidden  bg-black/50 backdrop-blur-md" >


                    <div className='w-[80%] p-10 grid gap-2 grid-cols-6 bg-black border rounded-3xl border-gray-600 bg-red-000 h-full'>

                        <div className='h-[85%] bg-slate-000 col-span-5 bg-red-000  space-y-3 flex flex-col justify-center'>
                            <p className="text-white text-xs font-bold lg:text-4xl">{header} </p>
                            <p className="text-gray-400 text-xs lg:text-2xl">From {from} To {to}</p>
                            <p className="text-gray-400 text-xs lg:text-xl flex items-center space-x-3">{date} | Departure at: {time}</p>
                        </div>

                        <div className='h-[85%] text-center rounded-xl col-span-1 hover:bg-orange-600 bg-orange-500 space-y-1 flex flex-col justify-center'>
                            {/* <p className="text-white text-xs lg:text-lg">Join Ride</p> */}
                            <button className='text-white text-3xl font-semibold' onClick={handleSubmit}> Join Ride </button>

                        </div>


                        <div className='h-[85%] text-left px-5 rounded-xl col-span-5 bg-gray-500/10 space-y-2 flex flex-col justify-center'>
                            <p className="text-orange-400 text-xs lg:text-lg">About Ride</p>
                            <p className="text-gray-400 text-xs lg:text-sm break-words">{description}</p>
                        </div>

                        <div className='h-[85%] text-center rounded-xl col-span-1 bg-gray-500/10 space-y-1 flex flex-col justify-center'>
                            <p className="text-orange-400 text-xs lg:text-lg">Contribution</p>
                            <p className="text-gray-400 text-xs lg:text-lg">₹{perHead} Per Head</p>
                        </div>

                        <div className='h-[85%] text-center rounded-xl col-span-6 py-2 flex-wrap bg-gray-500/10 space-x-3 flex justify-center items-center'>
                            <p className="text-white text-xs lg:text-lg">Ride join by</p> <br/>

                            {join.map((rider, index) => <p key={index} className="py-1 px-5 rounded-full bg-gray-300/10 text-gray-400 text-sm">{rider}</p>
                            )}
                        </div>


                        <div className='h-[15%] col-span-5 flex items-left space-x-3 bg-slate-000 py-3'>
                            <p className="text-gray-500 text-md lg:text-2xl " >Ride Organised by

                                <span className='text-orange-400 hover:text-orange-200 px-2'>

                                    <button onClick={() => navigate(`/${host}`)}>{host}</button>

                                </span> on {created.slice(0, 10)} </p>
                        </div>


                        <div className='h-[15%] col-span-1 flex items-left space-x-3 bg-slate-000 py-3'>

                            <button className='text-white' onClick={() => setEnlarge(false)}>Close Full Deatils</button>

                        </div>


                    </div>

                </div>

            }
            </>

    );











    const ViewMobile = () => (

        <>
        <div className="animate-fade-up rounded-2xl flex justify-end items-end p-5 animate-ease-linear border border-gray-800 hover:border-orange-500 duration-1000" >

            <div className='flex flex-col justify-center items-center space-y-3 bg-red-000 w-full h-full text-center'>

                <div className='h-[85%] bg-slate-000 w-full flex-grow space-y-3'>
                    <p className="text-white text-2xl">{header} </p>
                    <p className="text-white text-lg ">From {from} To {to}</p>
                    <p className="text-white text-xs ">On Date {date} | Departure at: {time}</p>
                </div>

                <button className='text-white bg-orange-500 px-3 py-1 rounded-full' onClick={() => setEnlarge(true)}>View Full Deatils</button>


                <div className='h-[15%] flex justify-evenly items-center space-x-3 py-3 w-full'>
                    <p className="text-gray-500 text-md  " >Ride Organised by

                        <span className='text-orange-400 hover:text-orange-200 px-2'>

                            <button onClick={() => navigate(`/${host}`)}>{host}</button>

                        </span> on {created.slice(0, 10)} 
                    </p>
                </div>

            </div>

        </div>


        {enlarge &&

            <div className="absolute w-screen h-[80%] top-0 left-0 z-10 rounded-2xl p-5 flex flex-wrap justify-center overflow-hidden  bg-black/50 backdrop-blur-md" >


                <div className='w-[80%] p-10 grid gap-2 grid-cols-6 bg-black border rounded-3xl border-gray-600 bg-red-000 h-full'>

                    <div className='h-[85%] bg-slate-000 col-span-5 bg-red-000  space-y-3 flex flex-col justify-center'>
                        <p className="text-white text-xs font-bold lg:text-4xl">{header} </p>
                        <p className="text-gray-400 text-xs lg:text-2xl">From {from} To {to}</p>
                        <p className="text-gray-400 text-xs lg:text-xl flex items-center space-x-3">{date} | Departure at: {time}</p>
                    </div>

                    <div className='h-[85%] text-center rounded-xl col-span-1 hover:bg-orange-600 bg-orange-500 space-y-1 flex flex-col justify-center'>
                        {/* <p className="text-white text-xs lg:text-lg">Join Ride</p> */}
                        <button className='text-white text-3xl font-semibold' onClick={handleSubmit}> Join Ride </button>

                    </div>


                    <div className='h-[85%] text-left px-5 rounded-xl col-span-5 bg-gray-500/10 space-y-2 flex flex-col justify-center'>
                        <p className="text-orange-400 text-xs lg:text-lg">About Ride</p>
                        <p className="text-gray-400 text-xs lg:text-sm break-words">{description}</p>
                    </div>

                    <div className='h-[85%] text-center rounded-xl col-span-1 bg-gray-500/10 space-y-1 flex flex-col justify-center'>
                        <p className="text-orange-400 text-xs lg:text-lg">Contribution</p>
                        <p className="text-gray-400 text-xs lg:text-lg">₹{perHead} Per Head</p>
                    </div>

                    <div className='h-[85%] text-center rounded-xl col-span-6 py-2 flex-wrap bg-gray-500/10 space-x-3 flex justify-center items-center'>
                        <p className="text-white text-xs lg:text-lg">Ride join by</p> <br/>

                        {join.map((rider, index) => <p key={index} className="py-1 px-5 rounded-full bg-gray-300/10 text-gray-400 text-sm">{rider}</p>
                        )}
                    </div>


                    <div className='h-[15%] col-span-5 flex items-left space-x-3 bg-slate-000 py-3'>
                        <p className="text-gray-500 text-md lg:text-2xl " >Ride Organised by

                            <span className='text-orange-400 hover:text-orange-200 px-2'>

                                <button onClick={() => navigate(`/${host}`)}>{host}</button>

                            </span> on {created.slice(0, 10)} </p>
                    </div>


                    <div className='h-[15%] col-span-1 flex items-left space-x-3 bg-slate-000 py-3'>

                        <button className='text-white' onClick={() => setEnlarge(false)}>Close Full Deatils</button>

                    </div>


                </div>

            </div>

        }
</>
);





    return width >= 450 ? ViewDesktop(): ViewMobile();
};




export default ViewRide;
