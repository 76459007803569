import React, { useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';
// import { supabase } from '../client';
import { createClient } from '@supabase/supabase-js'
import { supabase } from '../../client';



// const supabase = createClient("https://yjqfvaqpwdiblozmnruy.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlqcWZ2YXFwd2RpYmxvem1ucnV5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ4MjQ0ODQsImV4cCI6MjAzMDQwMDQ4NH0.lKlUqGH7wJJCQatUBMUikAtRYH5LjuX41XSwXoYuqJ8");



const SignUp = () => {

  const [formData,setFormData] = useState({
    fullName:'',email:'',password:''
  })

  function handleChange(event){
    setFormData((prevFormData)=>{
      return{
        ...prevFormData,
        [event.target.name]:event.target.value
      }

    })

  }

  async function handleSubmit(e){
    e.preventDefault()

    try {
      const { data, error } = await supabase.auth.signUp(
        {
          email: formData.email,
          password: formData.password,
          options: {
            data: {
              full_name: formData.fullName,
            }
          }
        }
      )
      if (error) throw error
      
      alert('Check your email for verification link')

      
    } catch (error) {
      alert(error)
    }
  }




  return (
<div className='fixed bg-black w-full h-full flex flex-col justify-center items-center text-white'>

<div className='w-[70%] bg-slate-500 h-fit rounded-full overflow-hidden mb-5'>
      <form onSubmit={handleSubmit} className='space-x-0  flex'>
        <input 
                  className='p-3 text-gray-900 w-full'

          placeholder='Fullname'
          name='fullName'
          onChange={handleChange}
        />

        <input 
          className='p-3 text-gray-900 w-full peer'
          placeholder='Email'
          name='email'
          onChange={handleChange}
        />

        <input 
          className='p-3 text-gray-900 w-full'
          placeholder='Password'
          name='password'
          type="password"
          onChange={handleChange}
        />

        <button className='px-10 bg-orange-600 hover:bg-orange-500' type='submit'>
          Submit
        </button>


      </form>
    </div>

    <div className='flex justify-center space-x-3 w-1/2 bg-slate-000 h-fit rounded-full overflow-hidden mb-5'>

<p className=''>
Already have an account?
</p>

<Link className='text-orange-300'to='/login'>Login</Link> 

</div>


    </div>

  )
}

export default SignUp