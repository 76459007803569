import { supabase } from '../../client';













export default async function NotificationInsert(username1, postId, table, tableKey, message) {



    console.log('notification data', username1, postId, table, tableKey, message);



    const getPostAuthorId = async (postId) => {

        try {

            const { data, error } = await supabase
                .from(table)
                .select(tableKey)
                .eq('post_id', postId)
                .single();

            if (error) {

                console.error("Error fetching post author ID:", error.message);
                return null;

            }

            return data[tableKey];

        } catch (error) {
            console.error("Error in getPostAuthorId:", error.message);
            return null;
        }
    };

    const username2 = await getPostAuthorId(postId);

    if (!username2) {
        console.error("Error: username2 (post author ID) is null or undefined");
        return;
    }

    try {
        const { data, error } = await supabase
            .from('notification')
            .insert([
                { by: username1, for: username2, post_id: postId, text: message }
            ]);

        if (error) {
            console.error("Error adding notification:", error.message);
        } else {
            console.log("Notification added:", data);
        }
    } catch (error) {
        console.error("Error in NotificationInsert:", error.message);
    }
};
