import React, { useState, useContext } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";
import GoogleMapReact from 'google-map-react';








const InsertRidePost = () => {
    
    const [toggle, setToggle] = useState(false);
    const { username1, Refresh, token, refresh } = useContext(MyContext);

    return (
        <>
            <button className='text-white hover:bg-orange-600 bg-orange-500 px-5 p-1 rounded-full' onClick={() => { setToggle(prevState => !prevState) }}>Create Ride</button>
            {toggle && <RidePost setToggle={setToggle} />}
        </>
    );
}











const RidePost = ({ setToggle }) => {

    const { username1, Refresh, token, refresh } = useContext(MyContext);
    const [uploadOk, setUploadOk] = useState(false);

    const [ride, setRide] = useState({
        user_id: token.user.id,
        header: '',
        from: '',
        to: '',
        description: '',
        perHead: '',
        time: '',
        date: '',
        joinBy: [username1],
        status:'active',
        host: username1 ? username1 : '',
        haltPoints: ''
    });

    const [haltPoints, setHaltPoints] = useState([{ haltPoint: '', time: '', description: '' }]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRide({ ...ride, [name]: value });
    };

    const handleHaltChange = (index, e) => {
        const { name, value } = e.target;
        const updatedHaltPoints = haltPoints.map((halt, i) => (
            i === index ? { ...halt, [name]: value } : halt
        ));
        setHaltPoints(updatedHaltPoints);
    };

    const addHaltPoint = () => {
        setHaltPoints([...haltPoints, { haltPoint: '', time: '', description: '' }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const rideWithHaltPoints = { ...ride, haltPoints };

        if (!ride.date) {
            console.error("Date field is empty.");
            return;
        }


        try {
            const { data, error } = await supabase
                .from('ride')
                .insert(rideWithHaltPoints);

            if (error) {
                console.error("Failed to insert ride data:", error.message);
                return;
            }

            console.log("Ride data inserted successfully:", data);
            setRide({
                user_id: token.user.id,
                header: '',
                from: '',
                to: '',
                description: '',
                perHead: '',
                time: '',
                date: '',
                joinBy: username1,
                status: '',
                host: username1,
                haltPoints: []
            });
            setHaltPoints([{ haltPoint: '', time: '', description: '' }]);
            setUploadOk(true);
            console.log('Data submitted successfully');
        } catch (error) {
            console.error("Error inserting data:", error.message);
        }
    };

    return (
        <div className="w-full h-[80%] absolute top-0 left-0 backdrop-blur-sm bg-black/60 flex flex-col justify-center items-center overflow-scroll">


            <div className="w-full h-full flex flex-col justify-center items-center">

                <p className="text-white text-3xl font-extrabold mb-5">Post Ride</p>
                <div className="w-[50%] h-auto rounded-xl backdrop-blur-sm bg-black/60 flex flex-col justify-center space-y-2 bg-black p-5 border border-gray-500">
                    <input
                        type="text"
                        name="header"
                        value={ride.header}
                        onChange={handleInputChange}
                        placeholder="Write Post header"
                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                    />
                    <input
                        type="text"
                        name="from"
                        value={ride.from}
                        onChange={handleInputChange}
                        placeholder="Ride starts from"
                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                    />
                    <input
                        type="text"
                        name="to"
                        value={ride.to}
                        onChange={handleInputChange}
                        placeholder="To - Destination"
                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                    />


                    <div className="flex overflow-x-scroll">
                        {haltPoints.map((halt, index) => (

                            <div key={index} className="bg-gray-900 m-1 p-1">
                                <div className="flex flex-col space-y-2">
                                    <input
                                        type="text"
                                        name="haltPoint"
                                        value={halt.haltPoint}
                                        onChange={(e) => handleHaltChange(index, e)}
                                        placeholder="Halt Point"
                                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                                    />
                                    <input
                                        type="text"
                                        name="time"
                                        value={halt.time}
                                        onChange={(e) => handleHaltChange(index, e)}
                                        placeholder="Time"
                                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                                    />
                                    <input
                                        type="text"
                                        name="description"
                                        value={halt.description}
                                        onChange={(e) => handleHaltChange(index, e)}
                                        placeholder="Description"
                                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                                    />
                                </div>
                            </div>
                        ))}


                        <button type="button" onClick={addHaltPoint} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                            Add Halt Point
                        </button>
                    </div>

                    <input
                        type="text"
                        name="description"
                        value={ride.description}
                        onChange={handleInputChange}
                        placeholder="Write about Ride"
                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                    />
                    <input
                        type="text"
                        name="perHead"
                        value={ride.perHead}
                        onChange={handleInputChange}
                        placeholder="Contribution in rupees (per head)"
                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                    />
                    <input
                        type="time"
                        name="time"
                        value={ride.time}
                        onChange={handleInputChange}
                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                    />
                    <input
                        type="date"
                        name="date"
                        value={ride.date}
                        onChange={handleInputChange}
                        className="border border-gray-700 rounded outline-none placeholder-gray-700 text-white bg-gray-400/10 p-2"
                    />
                    <button type="submit" onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        {uploadOk ? "Posted Successfully" : "Post"}
                    </button>
                    <button className='text-white my-10 hover:text-orange-600' onClick={() => { setToggle(false) }}>Close</button>
                </div>
            </div>
        </div>

    );
};




























const AnyReactComponent = ({ text }) => <div>{text}</div>;

function SimpleMap() {
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    return (
        <div className="w-full h-[40vw]">
            <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                />
            </GoogleMapReact>
        </div>
    );
}











const DistanceCalculator = () => {
    const [location1, setLocation1] = useState({ latitude: 0, longitude: 0 });
    const [location2, setLocation2] = useState({ latitude: 0, longitude: 0 });
    const [distance, setDistance] = useState(0);

    const calculateDistance = () => {
        const R = 6371; // Radius of the Earth in kilometers
        const lat1 = location1.latitude;
        const lon1 = location1.longitude;
        const lat2 = location2.latitude;
        const lon2 = location2.longitude;

        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers
        setDistance(distance);
    };

    return (
        <div className="container mx-auto p-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <h2 className="text-lg font-semibold mb-2">Location 1</h2>
                    <div className="flex flex-col mb-4">
                        <label className="text-gray-600">Latitude</label>
                        <input
                            type="number"
                            className="border rounded px-2 py-1"
                            value={location1.latitude}
                            onChange={(e) => setLocation1({ ...location1, latitude: parseFloat(e.target.value) })}
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <label className="text-gray-600">Longitude</label>
                        <input
                            type="number"
                            className="border rounded px-2 py-1"
                            value={location1.longitude}
                            onChange={(e) => setLocation1({ ...location1, longitude: parseFloat(e.target.value) })}
                        />
                    </div>
                </div>
                <div>
                    <h2 className="text-lg font-semibold mb-2">Location 2</h2>
                    <div className="flex flex-col mb-4">
                        <label className="text-gray-600">Latitude</label>
                        <input
                            type="number"
                            className="border rounded px-2 py-1"
                            value={location2.latitude}
                            onChange={(e) => setLocation2({ ...location2, latitude: parseFloat(e.target.value) })}
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <label className="text-gray-600">Longitude</label>
                        <input
                            type="number"
                            className="border rounded px-2 py-1"
                            value={location2.longitude}
                            onChange={(e) => setLocation2({ ...location2, longitude: parseFloat(e.target.value) })}
                        />
                    </div>
                </div>
            </div>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={calculateDistance}>
                Calculate Distance
            </button>
            <div className="mt-4">
                <p className="text-lg font-semibold">Distance:</p>
                <p className="text-xl">{distance.toFixed(2)} kilometers</p>
            </div>
        </div>
    );
};

export default InsertRidePost;
