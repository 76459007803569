import React, { useState, useContext, useEffect } from "react";
import { supabase } from '../../client';
import MyContext from "../Components/MyContext";










const AddasFriend = ({ username }) => {


    const { token, refresh, Refresh } = useContext(MyContext);
    const [added, setAdded] = useState(false);
    const [friendsList, setFriendsList] = useState([]);


    useEffect(() => {
        const fetchFriendsList = async () => {
            try {
                const { data, error } = await supabase
                    .from('user')
                    .select('friends')
                    .eq('user_id', token.user.id)
                    .single();

                if (error) {
                    console.error("Failed to fetch friends list:", error.message);
                    return;
                }

                if (friendsList.includes(username)) {
                    console.log("Username already exists in the friends list.");
                    setAdded(true);
                    return;
                }

                setFriendsList(data.friends || []);
            } catch (error) {
                console.error("Error fetching friends list:", error.message);
            }
        };

        fetchFriendsList();
    }, []);




    const notificationMessage = "Added you as frind";



    const handleSubmit = async () => {
        try {
            // Check if the username is already in the friends list
            if (friendsList.includes(username)) {
                console.log("Username already exists in the friends list.");
                setAdded(true);
                return;
            }

            const updatedFriendsList = [...friendsList, username];

            const { data, error } = await supabase
                .from('user')
                .update({ friends: updatedFriendsList })
                .eq('user_id', token.user.id);

            if (error) {
                console.error("Failed to add friend:", error.message);
                return;
            }

            console.log("Friend added successfully:");
            setAdded(true);
            


            // InsertNotification({ username, notificationMessage });

            Refresh();


        } catch (error) {
            console.error("Error adding friend:", error.message);
        }
    };

    return (
        <div className="w-full h-full">

            {

                added
                    ?
                    <p className='text-green-500'> Friends </p>
                    :
                    <button className='text-gray-500 hover:text-orange-300' onClick={handleSubmit}> Add as Friend </button>

            }

        </div>
    );
};

export default AddasFriend;
